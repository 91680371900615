// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-block {
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
}
@media (min-width: 668px) {
  .category-block {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .category-block {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.category-block__all-button {
  width: 100%;
  margin: auto;
  padding: 0 50px;
}
@media (min-width: 992px) {
  .category-block__all-button {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CategoryBlock/styles.scss"],"names":[],"mappings":"AAEA;EACC,cAAA;EACA,aAAA;EACA,0BAAA;EAEA,qBAAA;AAFD;AAIC;EAPD;IAQE,8BAAA;EADA;AACF;AAGC;EAXD;IAYE,sCAAA;EAAA;AACF;AAGC;EACC,WAAA;EACA,YAAA;EACA,eAAA;AADF;AAGE;EALD;IAME,aAAA;EAAD;AACF","sourcesContent":["@import \"/src/styles/variables\";\n\n.category-block {\n\tmargin: 0 20px;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\n\tgrid-column-gap: 20px;\n\n\t@media (min-width: $breakpoint-phone) {\n\t\tgrid-template-columns: 1fr 1fr;\n\t}\n\n\t@media (min-width: $breakpoint-tablet) {\n\t\tgrid-template-columns: 1fr 1fr 1fr 1fr;\n\t}\n\n\n\t&__all-button {\n\t\twidth: 100%;\n\t\tmargin: auto;\n\t\tpadding: 0 50px;\n\n\t\t@media (min-width: $breakpoint-tablet) {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
