import React, { useEffect, VFC } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Breadcrumbs, { Breadcrumb } from "~/components/Breadcrumbs";
import { useDispatch } from "~/store";
import { selectCategories } from "~/store/slices/categories";
import {
  fetchProductDetails,
  selectProductDetails
} from "~/store/slices/products";
import MoreAds from "./MoreAds";
import "./styles.scss";
import ProductDetailsBlock from "./ProductDetails";
import { Loading } from "react-loading-dot/lib";
import Colors from "~/consts/Colors";
import { Categories } from '~/consts/data/categories';

const ProductDetailsPage: VFC = () => {
  const dispatch = useDispatch();
  const { productId } =
    useParams<{ productId: string; category: Categories }>();
  const categories = useSelector(selectCategories);
  const product = useSelector(selectProductDetails);
  const token = localStorage.getItem("auth");

  useEffect(() => {
    dispatch(fetchProductDetails({ productId: Number(productId), token }));
  }, [location.pathname]);

  const isLoading = !product || product?.id !== Number(productId);
  if (isLoading) {
    return <Loading dots={3} background={Colors.PINK} />;
  }
  const currentCategory = categories.find(
    (category) => category?.id === product.category_id
  );
  const breadcrumb: Breadcrumb = {
    breadcrumb: product.title,
    match: { path: "productPage" },
  };
  return (
    <>
      <main className="product-details-page product-page">
        <div className="product-details-page__breadcrumbs">
          <Breadcrumbs currentBreadcrumb={ breadcrumb }/>
        </div>

         <ProductDetailsBlock product={ product } category={ currentCategory }/>

      </main>
      <MoreAds products={ product?.more_ads }/>
    </>
  );
};

export default ProductDetailsPage;
