import api, { ResponseWrapper } from "./axios";


export type Category = {
  id: number;
  name: string;
  slug?: string;
};

export type City = {
  id: number;
  name: string;
  name_arabic?: string;
  name_kurdish?: string;
};

export interface SubCategory extends Category {
  parentId: number;
}

const token = localStorage.getItem("auth");

export const fetchCategories = () =>
  api.get<ResponseWrapper<Category[]>>("/categories", {params: {token}});

export const fetchSubCategories = (id: number) =>
  api.get<ResponseWrapper<Category[]>>(`/sub-categories/${id}`, {params: {token}});

export const fetchCities = () => api.get<ResponseWrapper<City[]>>(`/cities`, {params: {token}});
