import React, { useEffect, useRef, useState, VFC } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetSuccessMsg, resetUserPassword, selectLang, selectResetPasswordShow } from "~/store/slices/user";
import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import TextInput from "../../components/FormInputs/TextInput";
import Button from "~/components/Button";
import { addErrorMessage } from '~/store/slices/snackbars';

type RecoveryPasswordType = {
  email: string;
}

const PasswordRecoveryPage: VFC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {t} = useTranslation();
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const status = useSelector(selectResetPasswordShow);

  const RecoverySchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Mail is required"),
  });

  const initialValues: RecoveryPasswordType = {
    email: "",
  };

  const handleSubmit = async (values: RecoveryPasswordType) => {
    setLoading(true);
    const response = await dispatch(resetUserPassword(values));
    if (response?.payload?.status === "ok") {
      formikRef?.current?.resetForm({values: initialValues})
    } else {
      switch (response?.payload?.system_message) {
        case 'Account is not active':
          dispatch(
            addErrorMessage({
              message: t("REGISTER.INACTIVE_ACCOUNT_INFO"),
              title: t("REGISTER.INACTIVE_ACCOUNT"),
            })
          );
          break;
        default:
          dispatch(
            addErrorMessage({
              message: t("REGISTER.INACTIVE_ACCOUNT_INFO"),
              title: t("REGISTER.INACTIVE_ACCOUNT"),
            })
          );
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetSuccessMsg());
  }, []);

  return (
    <section className="start-page">
      <div className="start-page__content">
        <h1 className="start-page__title">{ t('BUTTONS.RESET_PASSWORD') }</h1>
        <div className="reset-password-page">
          { !status.success && !status.failure &&
             <h4>{ t('REGISTER.RESET_PASSWORD') }</h4>
          }

          { status.success &&
           <div className="recover-message">
             <h4>{ t('REGISTER.RESET_PASSWORD_SUCCESS') }</h4>
           </div>
          }

          { status.failure &&
           <div className="recover-message recover-message-fail">
             <h4>{ t('REGISTER.RESET_PASSWORD_FAILURE') }</h4>
           </div>
          }

          <Formik
            initialValues={ initialValues }
            onSubmit={ handleSubmit }
            validationSchema={ RecoverySchema }
            innerRef={ formikRef }
          >
            { (formik: FormikProps<RecoveryPasswordType>) => {
              return (<>
                <form className="recovery-form">
                  <Field
                    type="email"
                    name="email"
                    component={ TextInput }
                    placeholder={ 'Enter your e-mail*' }
                  />
                  <Button
                    onClick={ formik.handleSubmit }
                    type="submit"
                    className="registration-form__submit"
                    loading={ loading }
                  >
                    { t("BUTTONS.RESET_PASSWORD") }
                  </Button>
                </form>
              </>)
            } }
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default PasswordRecoveryPage;
