import React, { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import {
  AccommodationProductDetails,
  Category,
  ElectronicsProductDetails,
  OtherProductDetails,
  ProductDetailsType,
  VehicleBrand,
  VehicleProductDetails,
  fetchSavedDelete,
  fetchSavedAdd, fetchMarkSold, DefaultResponse} from "~/api";
import i18n from "i18next";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { store, useDispatch } from "~/store";
import {
  fetchAllVehicleModels,
  fetchVehicleBrands,
} from "~/store/slices/vehicles";
import Button from "../Button";
import moment from "moment";
import Clock from "../../assets/icons/clock.svg";
import { CreateAdFields } from "../CreateAdForm/types";
import {
  fetchProductDetails,
  selectCreatedAdProducts,
  selectProductDetails,
  selectShowPreviewForm,
  setPreviewForm,
} from "~/store/slices/products";
import { selectLang, selectUserProfile, selectUserToken, selectAuthenticated } from "~/store/slices/user/selectors";
import LoadingDots from "../LoadingDots";
import { ClipLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";
import ShareModal from "./ShareModal";
import ModalWindow from '~/components/Modal';
import { addErrorMessage, addSuccessMessage } from '~/store/slices/snackbars';
import { AxiosResponse } from 'axios';
import { formatPrice } from '~/helpers';
import { fetchUserProfile } from '~/store/slices/user';

export type ProductDetailsProps = {
  product: ProductDetailsType;
  category: Category;
  previewMark?: boolean;
  onClick?: () => void;
  onSubmit?: (values: CreateAdFields) => void;
  submitLoading?: boolean;
};

export type PropertyField = {
  vehicles: (keyof VehicleProductDetails)[];
  accommodations: (keyof AccommodationProductDetails)[];
  electronics: (keyof ElectronicsProductDetails)[];
  other: (keyof OtherProductDetails)[];
  all: (keyof ProductDetailsType)[];
};

const selectModels = () => store.getState().vehicles.models;
const selectBrands = () => store.getState().vehicles.brands;

const ProductDetails: VFC<ProductDetailsProps> = ({
                                                    product,
                                                    category,
                                                    previewMark,
                                                    onSubmit,
                                                    submitLoading,
                                                  }) => {
  const profile = useSelector(selectUserProfile);
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const models = useSelector(selectModels);
  const brands = useSelector(selectBrands);
  const {productId} = useParams();
  const data = moment()
    .locale(
      i18n.language === "arab"
        ? "ar-sa"
        : i18n.language === "kur"
          ? "ku"
          : "en-gb"
    )
    .calendar();
  const token = useSelector(selectUserToken);
  const createdAdProducts = useSelector(selectCreatedAdProducts);
  const showPreviewForm = useSelector(selectShowPreviewForm);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const lang = useSelector(selectLang);
  const [confirmSoldModal, setConfirmSoldModal] = useState(false)
  const authenticated = useSelector(selectAuthenticated);

  const openModal = () => {
    setShowModal(true);
  };

  const productSaved = async (): Promise<void> => {
    if(!authenticated){
      history.push(`/${ lang }/register`);
      return;
    }
    try {
      const {data} = saved
        ? await fetchSavedDelete({ad_id: product.id, token})
        : await fetchSavedAdd({ad_id: product.id, token});
      if (data.status === "ok") {
        setSaved(!saved);
      } else {
        dispatch(addErrorMessage({
          title:t('ACTION.FAILURE'),
          message:t('ACTION.FAILURE_GENERIC_TEXT')
        }))
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  useEffect(() => {
    // new Promise<VehicleBrand[]>((res, rej) => {
    //   if (brands?.length < 1) {
    //     dispatch(fetchVehicleBrands())
    //       .unwrap()
    //       .then((response) => {
    //         res(response);
    //       })
    //       .catch((error) => {
    //         rej(error);
    //       });
    //   } else {
    //     res(brands);
    //   }
    // }).then((brands) => {
    //   if (models?.length < 1) {
    //     dispatch(fetchAllVehicleModels(brands));
    //   }
    // });

    setSaved(product.saved_ad);
  }, []);

  // const isLoading =
  //   !category || !product || brands?.length < 1 || models?.length < 1;
  const isLoading = !product;
  if (isLoading) {
    return <LoadingDots/>;
  }

  const handleConfirmSold = (showModal: boolean) => {
    setConfirmSoldModal(showModal);
  }

  const handleSoldAccept = async (): Promise<void> => {
    try {
      const response: AxiosResponse<DefaultResponse> = await fetchMarkSold({id: product.id, token});
      if (response?.data.status === "ok") {
        setConfirmSoldModal(false);
        dispatch(fetchProductDetails({productId: product.id, token}));
        dispatch(
          addSuccessMessage({
            title: t("ACTION.SUCCESS"),
            message: t("ACTION.SUCCESS_GENERIC_TEXT"),
          })
        );
        dispatch(fetchUserProfile(token));
      }
      if (response?.data.status === "error") {
        dispatch(
          addSuccessMessage({
            title: t("ACTION.FAILURE"),
            message: t("ACTION.FAILURE_MESSAGE"),
          })
        );
        setConfirmSoldModal(false);
      }
    } catch (e) {
      dispatch(
        addSuccessMessage({
          title: t("ACTION.FAILURE"),
          message: t("ACTION.FAILURE_MESSAGE"),
        })
      );
      setConfirmSoldModal(false);
    }
  }

  const ownerActions = () => {

    if (profile && profile.id !== product.user_id){
      return null;
    }

    const sold = product.status === "sold";
    const pending = product.status === "pending";
    const active = product.status === "active";

    return (
      <div className={"button-row space-between"}>

        { previewMark &&
           <Button
            onClick={ () => onSubmit(createdAdProducts) }
            className={ "product-details__create" }>
             { submitLoading ? (<ClipLoader color="white" size={ 20 }/>) : (t(`BUTTONS.PUBLISH`)) }
           </Button>
        }

        { !previewMark && soldButtonShow &&
         <>
           <Button disabled={ sold } onClick={ () => handleConfirmSold(true) }>
             { sold ? t('BUTTONS.SOLD') : t('BUTTONS.MARK_SOLD') }
           </Button>

           <ModalWindow isOpen={ confirmSoldModal } onClose={ () => handleConfirmSold(false) }>

             <h2>{ t('BUTTONS.MARK_SOLD') }</h2>
             <p>{ t('CONFIRM.SOLD') }</p>

             <div className={ 'button-row' }>

               <Button onClick={ () => handleConfirmSold(false) } className="profile-setting__cancel">
                 { t("common.CANCEL") }
               </Button>

               <Button onClick={ handleSoldAccept }>
                 { product.status === "sold" ? t("BUTTONS.SOLD") : t("BUTTONS.MARK_SOLD") }
               </Button>

             </div>

           </ModalWindow>
         </>
        }

        {!sold && !pending &&
         <Button
          disabled={false}
          className={ "product-details__edit" }
          onClick={ () => {
            if (product.id) {
              history.push(`/${lang}/ad/edit/${product.id}`)
            } else {
              return dispatch(setPreviewForm(false));
            }
          } }>
           <p>{ t("BUTTONS.EDIT_AD") }</p>
         </Button>
        }


      </div>
    );
  }


  const sold = product.status === "sold";
  const soldButtonShow = (profile?.type === "company" && product.user_id === profile?.id) || sold;
  const oldPrice = product?.price_history && product?.price_history?.length>0 && product.price_history[0].price;

  return (
    <div className="product-details">
      <ul className="product-details__list">
        <li className="product-details__list-item" key='00'>
            <span className="product-details__property-label">
              ID
            </span>
            <span className="product-details__property-value">
              {productId}
            </span>
        </li>
        { Object.keys(product)?.map(
          (property, index) =>
            i18n.exists(`DESCRIPTION_DATA.${ property.toLocaleUpperCase() }`) &&
            !!product[property] && (
              <li className="product-details__list-item" key={ index }>
                <span className="product-details__property-label">
                  { t(`DESCRIPTION_DATA.${ property.toLocaleUpperCase() }`) }
                </span>
                <span className="product-details__property-value">
                  { property === "name"
                    ? product.name_lastname
                    : product[property].hasOwnProperty('name') ? product[property]['name'] : product[property] }
                </span>
              </li>
            )
        ) }
      </ul>
      <div className="product-details__content">
        <div className="product-details__date-section">
          <Clock className="product-details__clock"/>
          { !!data && <span className="product-details__date">{ data }</span> }
        </div>

        <button onClick={ openModal } className="product-details__share">{ t("BUTTONS.SHARE") }</button>
        { showModal ? <ShareModal showModal={ setShowModal }/> : null }

        <span className={ `product-details__price ${ sold ? "sold" : "" }` }>
          {formatPrice(product.price)}{!!oldPrice && <span className={"old-price"}>{formatPrice(oldPrice)}</span>}
        </span>

        <div className="product-details__previewBtn">

          <Button
            disabled={ product.status === "pending" }
            onClick={ () => history.push({pathname: `/${ lang }/ad/${ product.id }/seller-profile`}) }
            className={ "product-details__contact" }>
            { t(`BUTTONS.CONTACT_SELLER`) }
          </Button>

          <Button
            disabled={ product.status === "pending" }
            className={ saved ? "product-details__save__active" : "product-details__save" }
            onClick={ () => {
              productSaved()
            } }
          >
            <p>{ t("BUTTONS.SAVE") }</p>
          </Button>

        </div>

        { ownerActions() }

      </div>
    </div>
  );
};

export default ProductDetails;
