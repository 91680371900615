import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import CategoryButton from "../CategoryButton";
import "./styles.scss";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import { ALL_CATEGORIES } from "~/consts/data/categories";
import useMediaQuery from "~/hooks/useMediaQuery";
import { useSelector } from "react-redux";
import { selectLang } from "~/store/slices/user";

const CategoryBlock: VFC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const isTablet = useMediaQuery("tablet>");
  const lang = useSelector(selectLang);
  return (
    <>
      <nav className="category-block">
        {ALL_CATEGORIES.map((categoryName, key) => {
          if (categoryName !== "all") {
            return (
              <CategoryButton
                iconSize={ isTablet ? "big" : "small" }
                key={ `category-button-${ categoryName }-${ key }` }
                variant={ categoryName }
                onClick={ () => {
                  history.push(`/${ lang }/products/${ categoryName }`);
                } }
              />
            )
          }
          return null
        })}
      </nav>
      <Button
          className="category-block__all-button"
          onClick={ () => {
          history.push(`/${ lang }/products/all`);
        } }
           key={ `category-button-all` }
      >
        { t("CATEGORIES.ALLADS") }
      </Button>
    </>

  );
};
export default CategoryBlock;
