import React, { useEffect, useState, VFC } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs, { Breadcrumb } from "~/components/Breadcrumbs";
import { useDispatch } from "~/store";
import {
  createAd,
  fetchProductDetails,
  selectProductDetails,
} from "~/store/slices/products";
import "./styles.scss";
import { Loading } from "react-loading-dot/lib";
import Colors from "~/consts/Colors";
import CreateAdForm from '~/components/CreateAdForm';
import { fetchUserProfile, selectLang, selectUserProfile, selectUserToken } from '~/store/slices/user';
import { t } from 'i18next';
import { CreateAdFields } from '~/components/CreateAdForm/types';
import { addErrorMessage, addSuccessMessage } from '~/store/slices/snackbars';
import { updateProductPrice } from '~/api';

const ProductEditPage: VFC = () => {
  const dispatch = useDispatch();
  const {productId} = useParams<{ productId: string }>();
  const product = useSelector(selectProductDetails);
  const profile = useSelector(selectUserProfile);
  const token = useSelector(selectUserToken);
  const [loading, setLoading] = useState(false);
  const lang = useSelector(selectLang);
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchProductDetails({productId: Number(productId), token}));
  }, [location.pathname]);

  const isLoading = !product || product?.id !== Number(productId) || !profile || !profile?.id;
  if (isLoading) {
    return <Loading dots={ 3 } background={ Colors.PINK }/>;
  }
  const breadcrumb: Breadcrumb = {
    breadcrumb: product.title,
    match: {path: "productPage"},
  };

  const canEdit = profile && product && profile.id === product.user_id;


  const handleSubmit = (fields: CreateAdFields) => {
    setLoading(true);
    updateProductPrice({
      id: product.id,
      price: parseInt(fields.price),
      token: token
    }).then((response) => {
      if (response.data.status === "ok") {
        dispatch(addSuccessMessage({
          title: t("ACTION.SUCCESS"),
          message: t("ACTION.SUCCESS_GENERIC_TEXT"),
        }));
        dispatch(fetchUserProfile(token));
        history.push(`/${ lang }/products/all/${ product.id }`);
      } else {
        dispatch(addErrorMessage({
          title: t("ACTION.FAILURE"),
          message: t("ACTION.FAILURE_GENERIC_TEXT"),
        }));
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  //TODO for now update just price....

  return (
    <>
      <main className="product-details-page product-page product-page-edit">
        <div className="product-details-page__breadcrumbs">
          <Breadcrumbs currentBreadcrumb={ breadcrumb }/>
        </div>

        { !canEdit &&
         <div>
           <div className="privacy-page__title">
             <h1 className="privacy-page__text">
               { t('ACTION.FAILURE_GENERIC_TEXT') }
             </h1>
           </div>
           { t('ACTION.FAILURE_NOT_ENOUGH_CREDITS') }
         </div>
        }

          <CreateAdForm
            onSubmit={ handleSubmit }
            submitLoading={ !product || loading }
            product={ product }
          />

      </main>
    </>
  );
};

export default ProductEditPage;
