import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchDeleteUser, fetchLogOutData } from "../../store/slices/user/actions";
import { selectLang, selectUserToken } from "../../store/slices/user/selectors";
import Button from "../Button";
import SignOutIcon from "../../assets/icons/sign-out.svg"
import DeleteAccIcon from "../../assets/icons/delete-acc.svg"
import { Link, useHistory } from "react-router-dom";
import ModalWindow from '~/components/Modal';

const AccountManage = (): JSX.Element => {
  const token = useSelector(selectUserToken);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const manageProfile = async (type: string) => {
    switch (type) {
      case 'delete':
      setShowDeleteConfirmation(true);
        break;
      case 'logout':
        try {
          dispatch(fetchLogOutData(token));
        } catch (e) {
          console.log(e?.response, "error");
        }
        break;
    }
  };

  useEffect(() => {
    if (!token) {
      history.push(`/${ lang }`);
    }
  }, [token]);


  const handleDeleteCancel = () =>{
    setShowDeleteConfirmation(false);
  }

  const handleDeleteAccept = () =>{
    try {
      dispatch(fetchDeleteUser(token));
      setShowDeleteConfirmation(false);
    } catch (e) {
      console.log(e?.response, "error");
    }
  }

  return (
    <>
      <div className="profile-setting__management">
        <div className="profile-setting__content">
          <h3 className="h3">{ t("PROFILE.ACCOUNT_MANAGE") }</h3>
          <div className="profile-setting__button-wrapper">
            <a onClick={ () => manageProfile("logout") } className="profile-setting__button">
              <SignOutIcon/>
              { t("PROFILE.SIGN_OUT") }
            </a>
            <a onClick={ () => manageProfile("delete") } className="profile-setting__button">
              <DeleteAccIcon/>
              { t("PROFILE.DELETE_ACCOUNT") }
            </a>
          </div>

          <Link to={`/${lang}/terms-of-use`} className="profile-setting__terms">{ t("PROFILE.READ_TERMS") }</Link>
        </div>
      </div>
      <ModalWindow isOpen={ showDeleteConfirmation } onClose={ handleDeleteCancel }>
        <h2>{ t("PROFILE.DELETE_ACCOUNT") }</h2>
        <p>{ t('CONFIRM.DELETE') }</p>
        <div className={ 'button-row' }>

          <Button onClick={ handleDeleteCancel } className="profile-setting__cancel">
            { t("common.CANCEL") }
          </Button>

          <Button onClick={ handleDeleteAccept }>
            { t("common.DELETE") }
          </Button>

        </div>
      </ModalWindow>
    </>

  );
};
export default AccountManage;
