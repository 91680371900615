import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeleteUser, fetchLogOutData, selectUserToken } from "~/store/slices/user";
import LanguageSwitcher from "../Header/LanguageSwitcher";
import NavItem from "../Header/NavItem";
import PlusAd from "~/assets/icons/plusAdd.svg";
import Heart from "~/assets/icons/heartWhite.svg";
import User from "~/assets/icons/userProfile.svg";
import SignOutIconBlack from "../../assets/icons/sign-out-black.svg";
import "./styles.scss";

export type HamburgerProps = {
  isAuthenticated: boolean
};

const Hamburger
: VFC<HamburgerProps> = ({ isAuthenticated }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const [open, setOpen] = useState<boolean>(false);
  const lang = localStorage.getItem("lang");

  const manageProfile = async (type: string) => {
    closeMenu();
    try {
      type === "logout"
        ? dispatch(fetchLogOutData(token))
        : dispatch(fetchDeleteUser(token));
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  const closeMenu = () => {
    setOpen(false);
  };



  return (
    <div>
      <div
        aria-label="Toggle menu"
        aria-expanded={open}
        className={`hamburger ${open ? 'hamburger_open' : ''} `}
        onClick={() => setOpen(!open)}
      >
        <span />
        <span />
        <span />
        <span />
      </div>

      <ul
        className={`header__menu-open ${open ? 'header__menu-open__opened' : "" } ${!isAuthenticated ? "header__menu_not_auth" : ""}`}
      >
        <>
          <NavItem
            icon={<PlusAd />}
            label={t("common.navigationNewAd")}
            to={`/${lang}/create-ad`}
            className="nav-item--button"
            onClick={closeMenu}
          />
          <NavItem
            icon={<Heart />}
            label={t("common.navigationSaved")}
            to={`/${lang}/saved`}
            onClick={closeMenu}
          />
          <NavItem
            icon={<User />}
            label={t("common.navigationProfile")}
            to={`/${lang}/profile`}
            onClick={closeMenu}
          />
          {isAuthenticated && <NavItem
           className="sign-out"
           label={t("PROFILE.SIGN_OUT")}
           icon={<SignOutIconBlack />}
           onClick={() => manageProfile("logout")}
           to={`/${lang}/`}
          />}

        </>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </div>
  );
};

export default Hamburger;
