import { RootState } from "~/store";
import { City } from '~/api';

export const selectCategories = (store: RootState) =>
  store.categories.categories;

export const selectSubCategories = (store: RootState) =>
  store.categories.subCategories;

export const selectSubCategoriesByParent = (id: number) => (store: RootState) =>
  store.categories.subCategories.filter((category) => category.parentId === id);

export const selectCities =  (store: RootState) => {
  const cities = store.categories.cities;
  return cities as City[];
}
