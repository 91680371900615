import React, { useState } from "react";

import i18n from "../../i18n/config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLang } from "../../store/slices/user/actions";
import { selectLang } from "../../store/slices/user/selectors";
import Eng from "../../assets/images/languages/en-language.png";
import Kur from "../../assets/images/languages/kur-language.png";
import Arb from "../../assets/images/languages/arab-language.png";
import CloseBtn from "../../assets/icons/close-button-nav.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

const LanguageScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useSelector(selectLang);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggling = () => setShowDropdown(!showDropdown);


  const languageItems = [
    { label: t("common.English"), value: "en", img: Eng },
    { label: t("common.Arabic"), value: "arab", img: Arb },
    { label: t("common.Kurdish"), value: "kur", img: Kur },
  ];

  const languageHeader = [
    { label: t("common.Eng"), value: "en" },
    { label: t("common.Arb"), value: "arab"},
    { label: t("common.Kur"), value: "kur"},
  ];

  const changeLanguage = async (lan: string) => {
    i18n.changeLanguage(lan);
    dispatch(changeLang(lan));
    localStorage.setItem("lang", lan);
    setShowDropdown(false);

    // I18nManager.forceRTL(lan === "arab" || lan === "kur");
    // RNRestart.Restart();
  };

  const getLabel = () => {
    return languageHeader.filter(item => item.value === lang)?.[0]?.label || t("common.Eng")
  }

  return (
    <div onClick={toggling} className="nav-item">
      {!showDropdown ?
        <>
          <img src={lang === "en" || lang === null ? Eng : lang === "kur" ? Kur : Arb} className="drop-down__flag"/>
          <span className="drop-down__title">{getLabel()}</span>
        </>
         : <CloseBtn className="close__btn"/>
      }
      {showDropdown && (
        <div className="mobile-drop-down">
          <ul className="mobile-drop-down__list">
            <h2 className="mobile-drop-down__header">{t("common.SELECT_LANG")}</h2>
            {languageItems.map((lan, index) => (
              <li onClick={() => changeLanguage(lan.value)} key={index} className={"mobile-drop-down__listItem"}>
                {lang === lan.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} />
                ) : <FontAwesomeIcon icon={faCheckCircle} className="checkIcon"/> }
                  <p className="mobile-drop-down__label">
                    {lan?.label || t("common.Eng")}
                  </p>
                  <img
                    className="drop-down__flag"
                    src={lan.img}
                    alt="Language Flag"
                  />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default LanguageScreen;
