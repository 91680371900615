// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-details-page__cards .product-card__content {
  margin: 0 20px 30px 20px;
}
.product-details-page__cards .product-card__acomodation-content {
  padding: 0 20px 30px 20px;
}
.product-details-page__cards .product-card__title {
  margin: 10px 20px;
}
.product-details-page__cards .product-card__price_block {
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductDetailsPage/more-ads.scss"],"names":[],"mappings":"AAGE;EACE,wBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAME;EACE,iBAAA;AAJJ;AAME;EACE,gBAAA;AAJJ","sourcesContent":["@import \"/src/styles/variables\";\n\n.product-details-page__cards {\n  .product-card__content {\n    margin: 0 20px 30px 20px;\n  }\n\n  .product-card__acomodation-content {\n    padding: 0 20px 30px 20px;\n  }\n\n  .product-card__title {\n    margin: 10px 20px;\n  }\n  .product-card__price_block {\n    margin-top: 50px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
