import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldAttributes, FieldInputProps, useField } from "formik";
import React, { FC, useState } from "react";
import useMediaQuery from "~/hooks/useMediaQuery";

export type OptionProps = {
  label: string;
  checked: boolean;
  name: string;
  name_kurdish?: string;
  name_arabic?: string;
  onChange: () => void;
  field: FieldInputProps<RadioProps>;
};

export type Option = {
  label: string;
  id: number;
  name: string;
  name_kurdish?: string;
  name_arabic?: string;
  value: any;
};

export type RadioProps = {
  items: Option[];
  name?: string;
  name_arabic?: string;
  name_kurdish?: string;
  onClick?: (index: number) => void;
};

export const Option: FC<OptionProps> = ({
  label,
  checked,
  name,
  name_kurdish,
  name_arabic,
  field,
  onChange,
}) => {
  return (
    <label className="radio-button" tabIndex={0} role="button">
      <input
        {...field}
        className="radio-button__input"
        type="radio"
        name={name}
        checked={checked}
        onChange={(e) => {
          field.onChange(e);
          onChange();
        }}
      />
      <FontAwesomeIcon icon={faCheck} className="radio-button__checkmark" />
      <span className="radio-button__title">{label}</span>
    </label>
  );
};

const Radio: FC<FieldAttributes<RadioProps>> = (props) => {
  const { items, name, onClick } = props;
  const [field, meta] = useField(props);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isTablet = useMediaQuery("tablet>");

  return (
    <>
      {items
        ?.filter((item) =>
          isTablet ? item.value?.id !== field?.value?.id : true
        )
        .map((item, key) => (
          <Option
            field={field}
            key={`${field.name}-${item.id}-${item.value}`}
            label={item?.label}
            checked={item.value?.id === field?.value?.id}
            name={field.name}
            onChange={() => {
              setSelectedIndex(key);
              onClick(item?.value);
            }}
          />
        ))}
    </>
  );
};

export default Radio;
