import { Profile } from "./../../../api/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loginUser,
  fetchUserProfile,
  setSocialLogin,
  setProfileType,
  changeLang,
  setToken,
  fetchLogOutData, fetchSellerProfile, resetUserPassword, resetSuccessMsg, changeUserPassword,
} from "./actions";
import { Product, ProfileSettingsModel } from "~/api";
import { fetchDeleteUser } from ".";

export type UserState = {
  user?: Profile;
  profilePasswordData: ProfileSettingsModel;
  token?: string;
  ads?: Product[];
  lang?: string;
  socialLogin: boolean;
  sellerData: Profile;
  sellerDataLoading: boolean;
  sellerDataError: boolean;
  resetPasswordShowSuccessMsg: boolean;
  resetPasswordShowFailedMsg: boolean;
  recoveryShowMsg: boolean;
};

const initialState: UserState = {
  user: null,
  profilePasswordData: {
    password: "",
    passwordRepeat: "",
    new_password: "",
    newPasswordRepeat: "",
    type: "private",
  },
  token: null,
  ads: null,
  lang: null,
  socialLogin: false,
  sellerData: null,
  sellerDataLoading:false,
  sellerDataError: false,
  resetPasswordShowSuccessMsg: false,
  resetPasswordShowFailedMsg: false,
  recoveryShowMsg: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Profile>) => {
      state.user = {...state, ...action.payload};
    },
    setSellerData: (state, action: PayloadAction<SellerProfile>) => {
      state.sellerData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetUserPassword.pending, (state, action) => {
        state.resetPasswordShowSuccessMsg = false;
        state.resetPasswordShowFailedMsg = false;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        const {status} = action.payload;
        if (status==="ok"){
          state.resetPasswordShowSuccessMsg = true;
          state.resetPasswordShowFailedMsg = false;
        }
        if (status==="error"){
          state.resetPasswordShowSuccessMsg = false;
          state.resetPasswordShowFailedMsg = true;
        }
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.resetPasswordShowSuccessMsg = false;
        state.resetPasswordShowFailedMsg = true;
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        const {status} = action.payload;
        state.recoveryShowMsg = true;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.recoveryShowMsg = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const {user_token} = action.payload;

        state.token = user_token || null;
        if (user_token) localStorage.setItem("auth", user_token);
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const {profile, ads} = action.payload;
        if (profile) {
          state.user = profile;
          state.ads = ads;
        } else {
          state.token = null;
          localStorage.removeItem("auth");
        }
      })
      .addCase(changeLang, (state, action) => {
        state.lang = action.payload;
      })
      .addCase(fetchLogOutData.fulfilled, (state) => {
        state.token = null;
        localStorage.removeItem("auth");
      })
      .addCase(fetchDeleteUser.fulfilled, (state) => {
        state.token = null;
        localStorage.removeItem("auth");
      })
      .addCase(setSocialLogin, (state, action) => {
        state.socialLogin = action.payload;
      })
      .addCase(setProfileType, (state, action) => {
        state.profilePasswordData.type = action.payload;
      })
      .addCase(setToken, (state, action) => {
        state.token = action.payload;
        if (action.payload) {
          localStorage.setItem("auth", action.payload);
        } else {
          localStorage.removeItem("auth");
        }
      })
      .addCase(fetchSellerProfile.rejected, (state, action) => {
        state.sellerData = null;
        state.sellerDataLoading = false;
        state.sellerDataError = true;
      })
      .addCase(fetchSellerProfile.pending, (state, action) => {
        state.sellerData = null;
        state.sellerDataLoading = true;
        state.sellerDataError = false;
      })
      .addCase(fetchSellerProfile.fulfilled, (state, action) => {
        state.sellerData = action.payload.data;
        state.sellerData.ads = action.payload.ads;
        state.sellerDataLoading = false;
        state.sellerDataError = false;
      })
      .addCase(resetSuccessMsg, (state, action) => {
        state.resetPasswordShowSuccessMsg = false;
        state.resetPasswordShowFailedMsg = false;
        state.recoveryShowMsg = false;
      })
  },
});

export const {setUser, setSellerData} = userSlice.actions;
export const userReducer = userSlice.reducer;
