import React, { VFC } from "react";
import { Field, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import TextInput from "../FormInputs/TextInput";
import * as Yup from "yup";
import "./styles.scss";
// import i18next from "i18next";
import i18n from "~/i18n/config";
import Hint from '~/assets/icons/hint.svg'

export type RegistrationModelFirstStep = {
  email: string;
  emailRepeat: string;
  password: string;
  passwordRepeat: string;
  rememberMe: boolean;
  type: "private" | "company";
};

export type RegistrationFormProps = {
  onSubmit: (values: RegistrationModelFirstStep) => void;
  type: "private" | "company";
  initialValues: RegistrationModelFirstStep;
};

const getErr = (key: string) => {
  return i18n.t(`${key}`);
}

const RegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("ERRORS.EMAIL_INVALID"))
    .required(getErr("ERRORS.EMAIL_REQ")),
  emailRepeat: Yup.string()
    .oneOf([Yup.ref("email"), null], i18n.t("ERRORS.EMAIL_REPEAT"))
    .required(getErr("ERRORS.EMAIL_REPEAT_REQ")),
  password: Yup.string()
    .min(5, i18n.t("ERRORS.PASS_INVALID"))
    .required(i18n.t("ERRORS.PASS_REQ")),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref("password"), null], i18n.t("ERRORS.PASS_REPEAT"))
    .required(i18n.t("ERRORS.PASS_REPEAT_REQ")),
});

const RegistrationForm: VFC<RegistrationFormProps> = ({
  onSubmit,
  type,
  initialValues,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (values: RegistrationModelFirstStep) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={RegistrationSchema}
    >
      {(formik: FormikProps<RegistrationModelFirstStep>) => {
        return (
          <form className="registration-form">
            <Field
              type="email"
              name="email"
              component={TextInput}
              placeholder={t("PLACEHOLDERS.EMAIL")}
              label={t("LABELS.EMAIL")}
            />
            <Field
              type="email"
              name="emailRepeat"
              component={TextInput}
              placeholder={t("PLACEHOLDERS.REPEAT_EMAIL")}
            />
            <Field
              type="password"
              name="password"
              component={TextInput}
              placeholder={t("PLACEHOLDERS.PASSWORD")}
              label={t("LABELS.PASSWORD")}
            />
            <Field
              type="password"
              name="passwordRepeat"
              component={TextInput}
              placeholder={t("PLACEHOLDERS.REPEAT_PASSSWORD")}
            />
            <label style={{display: 'flex', alignItems: 'center'}}>
              <Field type="checkbox" name="rememberMe" />
              <p>{t("REGISTER.REMEMBER_ME")}</p>
              <Hint />
            </label>
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              className="registration-form__submit"
            >
              {t("BUTTONS.CONTINUE")}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default RegistrationForm;
