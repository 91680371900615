import i18n from "i18next";
import HttpBackend from 'i18next-http-backend';
import en from "./locales/en.json";
import { initReactI18next } from "react-i18next";
import ar from "./locales/arab.json";
import ku from "./locales/kur.json";
import LanguageDetector from "i18next-browser-languagedetector";

export const resources = {
  en,
  ar,
  ku,
} as const;

i18n.use(initReactI18next).use(HttpBackend).use(LanguageDetector).init({
  lng: "en",
  // ns: ['en', 'ar', 'ku'],
  fallbackLng: "en",
  debug: true,
  // resources,
  // keySeparator: false,
  // nsSeparator: ".",
  partialBundledLanguages: true,
  backend: {
    loadPath: function (lngs: any) {
      const langMap = {
        en: 'en',
        arab: 'ar',
        kur: 'ku'
      }
      return `https://flycompany.stage.awave.host/api/v1/settings/translations/${langMap[lngs]}`
    },
    // crossDomain: true,
  },
  react: {
    useSuspense: true, // Включаем использование Suspense
  },
});

export default i18n;
