import React, { ReactNode, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setDataFromWatchList } from "~/store/slices/products";
import {fetchFiltersView} from "~/api/product";
import "../ProductCard/styles.scss";
import {selectUserToken} from "~/store/slices/user";
export type ProductCardProps = {
  id: number;
  title: string;
  date: string;
  newAds: number;
  className?: string;
  filterData?: any;
  icon?: ReactNode;
  handleDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => void;
  setListResult: (x: boolean) => void;
};
const WatchlistCard: VFC<ProductCardProps> = ({
  id,
  title,
  date,
  newAds,
  filterData,
  className = "",
  icon,
  handleDelete,
  setListResult,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);

  const setFiltersBlock = () => {
    // console.log('--->>> ', id)
    fetchFiltersView(token, id).then((data: any) => {
      // console.log('DATA: ', data);
      if (filterData) {
        dispatch(
          setDataFromWatchList({
            filters: data.data.filter,
            title,
            id,
          })
        );
      }
      setListResult(true);
    })
  };

  return (
    <div
      className={`product-card ${className}`}
      onClick={setFiltersBlock}
      style={{ cursor: "pointer" }}
    >
      <div className="product-card__watchlist">
        {!!title && (
          <span className="product-card__watchlist-title">{title}</span>
        )}
        {!!date && <span className="product-card__date">{date}</span>}
          <span className="product-card__watchlist-new-ads">
            {t("SAVED.NEW_ADS")}: {newAds}
          </span>
        {!!icon && (
          <button
            onClick={(e) => handleDelete(e, id)}
            className="product-card__watchlist-icon"
          >
            {icon}
          </button>
        )}
      </div>
    </div>
  );
};
export default WatchlistCard;
