import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Logo from "~/assets/icons/noResultPage-icon.svg";

import "./styles.scss";
import Button from "~/components/Button";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLang, selectUserProfile, selectAuthenticated } from "~/store/slices/user";
import { addErrorMessage } from '~/store/slices/snackbars';

export type NoResultPageProps = {
  specialMark: boolean;
};

const NoResultPage: FunctionComponent<NoResultPageProps> = ({
  specialMark = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const profile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const noCredits = profile?.credits===0 && isAuthenticated;


  return (
    <main
      className={`${"noResult-page"} ${
        specialMark && "noResult-page__specialMark-style"
      }`}
    >
      <h2 className="noResult-page__title">{t("ADS.NO_RESULT")}</h2>
      <Logo className="noResult-page__logo" />
      <div className="noResult-page__text">{t("ADS.NO_RESULT_TEXT")}</div>
      <div className="noResult-page__noResultBtn">
        <Button onClick={() => {
          if (!noCredits){
            history.push(`/${lang}/create-ad`)
          } else {
            dispatch(addErrorMessage({
              title:t('ACTION.FAILURE'),
              message:t('ACTION.FAILURE_NOT_ENOUGH_CREDITS')
            }))
          }
        }}>
          {t("BUTTONS.CREATE_NEW_AD")}
        </Button>
      </div>
    </main>
  );
};
export default NoResultPage;
