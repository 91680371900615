import React, { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoginModel } from "~/api";
import LoginForm from "~/components/LoginForm";
import { useDispatch } from "~/store";
import { fetchVerifyEmail, loginUser, selectLang, setProfileType, setSocialLogin } from "~/store/slices/user";
import "./styles.scss";
import { addErrorMessage, addSuccessMessage } from "~/store/slices/snackbars";
import { useSelector } from "react-redux";

const LoginPage: VFC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const lang = useSelector(selectLang);

  const {search} = location;
  const urlParams = new URLSearchParams(search);
  const verifyToken = urlParams.get('token');
  const verifyEmail = urlParams.get('email');

  const handleLogin = (credentials: LoginModel) => {
    setLoading(true);
    dispatch(loginUser(credentials))
      .unwrap()
      .then((response) => {
        if (response.status === "ok") {
          dispatch(setSocialLogin(false));
          dispatch(setProfileType(response.user_type));
          setLoading(false);
          history.push(`/${ lang }`);
        } else {
          if (response.status === "error") {
            switch (response.system_message) {
              case 'User inactive':
                dispatch(
                  addErrorMessage({
                    message: t("REGISTER.INACTIVE_ACCOUNT_INFO"),
                    title: t("REGISTER.INACTIVE_ACCOUNT"),
                  })
                );
                break;
              default:
                dispatch(
                  addErrorMessage({
                    message: t("FACEBOOK.LOGIN_FAILED"),
                    title: t("FACEBOOK.LOGIN_FAILED"),
                  })
                );
            }
            setLoading(false);
            history.push(`/${ lang }/login`);
          }
        }
      }).catch((error) => {
      console.log(error);
      setLoading(false);
      history.push(`/${ lang }/login`);
    })
  };

  const handleVerifyEmail = async (): Promise<void> => {
    try {
      const {data} = await fetchVerifyEmail(verifyEmail, verifyToken);
      setLoading(false);
      dispatch(
        addSuccessMessage({
          message: t("REGISTER.VERIFY_EMAIL_SUCCESS"),
          title: t("REGISTER.VERIFY_EMAIL"),
        })
      );
    } catch (e) {
      setLoading(false);
      dispatch(
        addErrorMessage({
          message: t("REGISTER.VERIFY_EMAIL_FAILED"),
          title: t("REGISTER.VERIFY_EMAIL"),
        })
      );
    }
  }

  useEffect(() => {
    if (verifyEmail && verifyToken) {
      setLoading(true);
      handleVerifyEmail();
    }
  }, [verifyEmail, verifyToken]);

  return (
    <main className="login-page">
      <div className="login-page__form">
        <LoginForm email={ verifyEmail } onSubmit={ handleLogin } loading={ loading }/>
      </div>
    </main>
  );
};
export default LoginPage;
