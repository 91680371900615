import React, { FC, useEffect, useRef, useState, VFC } from "react";
import "./styles.scss";
import FilterButton from "./FilterButton";
import CategorySelector from "./CategorySelector";
import useFullscreenModal from "~/hooks/useFullscreenModal";
import FullscreenModal from "../FullscreenModal";
import Dropdown from "./Dropdown";
import { useTranslation } from "react-i18next";
import { Category, fetchFiltersSaved } from "~/api";
import VehicleFilter from "./Forms/VehicleFilter";
import {
  fetchFilteredProducts,
  Filter as TFilter,
} from "~/store/slices/products";
import { useDispatch } from "~/store";
import AccommodationsFilter from "./Forms/AccommodationsFilter";
import AllFilter from "./Forms/AllFilter";
import OtherFilter from "./Forms/OtherFilter";
import ElectronicsFilter from "./Forms/ElectronicsFilter";
import useMediaQuery from "~/hooks/useMediaQuery";
import { OrderType } from "~/consts/data/order";
import { useSelector } from "react-redux";
import { selectUserToken } from "~/store/slices/user/selectors";
import { FilterFields } from "./Forms/FilterForm";

export type FilterProps = {
  category?: Category;
  className?: string;
  order: OrderType;
  onChange?: (value: any, categpryId: number) => void;
};

const Filter: VFC<FilterProps> = ({ category, className = "", order , onChange}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery("tablet>");
  const filter = useRef<any>(null);
  const filterValues = filter.current;
  const token = useSelector(selectUserToken);
  const setFilterValues = (value: any) => {
    filter.current = value;
    value !== null && onChange && onChange(value, category.id);
  };
  const [isFilterOpen, setIsFilterOpen] = useFullscreenModal(false);
  const [loading, setLoading] = useState(false);
  const openFilter = () => {
    setIsFilterOpen(true);
  };
  const closeFilter = () => {
    setLoading(false);
    setIsFilterOpen(false);
  };
  const SharedCategoriesFields: FC = ({ children }) =>
    !isTablet ? (
      <FullscreenModal isOpen={isFilterOpen} onClose={closeFilter}>
        <Dropdown
          id={'category'}
          label={t("translation:FILTERS.TITLE")}
          value={category ? t(`translation:CATEGORIES.${category.name?.toUpperCase()}`) : ""}
        >
          <CategorySelector closeModal={closeFilter} />
        </Dropdown>
        {children}
      </FullscreenModal>
    ) : (
      <>
        <h2>{t("translation:FILTERS.FILTER_TITLE")}</h2>
        <h3>{t("translation:FILTERS.TITLE")}</h3>
        <CategorySelector selectedCategory={category?.slug} />
        {children}
      </>
    );

  useEffect(() => {
    dispatch(
      fetchFilteredProducts({
        categoryId: category.id,
        filter: { ...filterValues },
        order,
      })
    );
  }, [order]);

  const handleSubmit = async (values: FilterFields) => {
    // console.log('Values:: ', JSON.stringify(values, null, 4));
    setLoading(true);
    setFilterValues(values);
    await dispatch(
      fetchFilteredProducts({
        categoryId: category.id,
        filter: { ...values },
        order,
      })
    );

    if(values.watchlist){
      try {
        const { data } = await fetchFiltersSaved(token, {
          category: values.category_id || category.id,
          sub_category: values.sub_category?.id || "",
          city: values.city?.id,
          price_min: values.price_min,
          price_max: values.price_max,
          vehicle_brand: values.vehicle_brand?.id || "",
          vehicle_model: values.vehicle_model?.id || "",
          vehicle_fuel: values.vehicle_fuel?.name || "",
          vehicle_gearbox: values.vehicle_gearbox?.name || "",
          vehicle_milage_min: values.vehicle_milage_min,
          vehicle_milage_max: values.vehicle_milage_max,
          vehicle_year_min: values.vehicle_year_min,
          vehicle_year_max: values.vehicle_year_max,
          accommodation_square_meter_min: values.accommodation_square_meter_min,
          accommodation_square_meter_max: values.accommodation_square_meter_max,
          accommodation_bedrooms_min:
              values.accommodation_bedrooms_min?.name || "",
          accommodation_bedrooms_max:
              values.accommodation_bedrooms_max?.name || "",
          accommodation_sale_rent: values.accommodation_sale_rent?.name || "",
          accommodation_type: values.accommodation_type?.name || "",
        });
        if (data.status === "ok") {
          closeFilter();
        }
        setLoading(false);
      } catch (e) {
        console.log(e, "error");
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const saveToWatchlist = async (values: FilterFields) => {
    // console.log('Values: ', values)
    // console.log('saveToWatchlist:: ', {
    //   category: values.category_id || category.id,
    //   sub_category: values.sub_category?.id || "",
    //   city: values.city?.id,
    //   price_min: values.price_min,
    //   price_max: values.price_max,
    //   vehicle_brand: values.vehicle_brand?.id || "",
    //   vehicle_model: values.vehicle_model?.id || "",
    //   vehicle_fuel: values.vehicle_fuel?.name || "",
    //   vehicle_gearbox: values.vehicle_gearbox?.name || "",
    //   vehicle_milage_min: values.vehicle_milage_min,
    //   vehicle_milage_max: values.vehicle_milage_max,
    //   vehicle_year_min: values.vehicle_year_min,
    //   vehicle_year_max: values.vehicle_year_max,
    //   accommodation_square_meter_min: values.accommodation_square_meter_min,
    //   accommodation_square_meter_max: values.accommodation_square_meter_max,
    //   accommodation_bedrooms_min:
    //       values.accommodation_bedrooms_min?.name || "",
    //   accommodation_bedrooms_max:
    //       values.accommodation_bedrooms_max?.name || "",
    //   accommodation_sale_rent: values.accommodation_sale_rent?.name || "",
    //   accommodation_type: values.accommodation_type?.name || "",
    // });
    try {
      await fetchFiltersSaved(token, {
        category: values.category_id || category.id,
        sub_category: values.sub_category?.id || "",
        city: values.city?.id,
        price_min: values.price_min,
        price_max: values.price_max,
        vehicle_brand: values.vehicle_brand?.id || "",
        vehicle_model: values.vehicle_model?.id || "",
        vehicle_fuel: values.vehicle_fuel?.name || "",
        vehicle_gearbox: values.vehicle_gearbox?.name || "",
        vehicle_milage_min: values.vehicle_milage_min,
        vehicle_milage_max: values.vehicle_milage_max,
        vehicle_year_min: values.vehicle_year_min,
        vehicle_year_max: values.vehicle_year_max,
        accommodation_square_meter_min: values.accommodation_square_meter_min,
        accommodation_square_meter_max: values.accommodation_square_meter_max,
        accommodation_bedrooms_min:
            values.accommodation_bedrooms_min?.name || "",
        accommodation_bedrooms_max:
            values.accommodation_bedrooms_max?.name || "",
        accommodation_sale_rent: values.accommodation_sale_rent?.name || "",
        accommodation_type: values.accommodation_type?.name || "",
      });
    } catch (e) {
      console.log(e, "error");
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(false);
    filter.current=null;
    dispatch(
      fetchFilteredProducts({
        categoryId: category.id,
        filter: null,
        order,
      })
    );
  }, [category.name]);

  const filterProps = {
    category,
    filterValues,
    setFilterValues,
    loading,
    onSubmit: handleSubmit,
    saveWatchlist: saveToWatchlist,
  };

  return (
    <div className={className}>
      <FilterButton onClick={openFilter} />

      <SharedCategoriesFields>
        {category.name === "Vehicle" ? (
          <VehicleFilter {...filterProps} />
        ) : category.name === "Accommodation" ? (
          <AccommodationsFilter {...filterProps} />
        ) : category.name === "Electronics" ? (
          <ElectronicsFilter {...filterProps} />
        ) : category.name === "Other" ? (
          <OtherFilter {...filterProps} />
        ) : (
          <AllFilter {...filterProps} />
        )}
      </SharedCategoriesFields>
    </div>
  );
};
export default Filter;
