import { Formik, Field } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Category, City, SubCategory } from "~/api";
import {
  selectCities,
  selectSubCategoriesByParent,
} from "~/store/slices/categories";
import FilterForm, { numberField, radioField } from "./FilterForm";
import Button from "~/components/Button";
import Colors from "~/consts/Colors";

import {
  ACCOMMODATION_BEDROOM_AMOUNT,
  ACCOMMODATION_SALE_RENT,
  ACCOMMODATION_TYPES,
} from "~/consts/data/accommodations";
import { ClipLoader } from "react-spinners";
import { oneOfTheFiltersSelected } from '~/helpers';
// import {VehicleFilterFields} from "~/components/Filter/Forms/VehicleFilter";

export type AccommodationsFilterFields = {
  category_id: string,
  city?: City;
  sub_category?: SubCategory;
  price_min?: number;
  price_max?: number;
  accommodation_square_meter_min?: number;
  accommodation_square_meter_max?: number;
  accommodation_bedrooms_min?: number;
  accommodation_bedrooms_max?: number;
  accommodation_sale_rent?: string;
  accommodation_type?: string;
};

export type AccommodationsFilterProps = {
  category: Category;
  filterValues?: AccommodationsFilterFields;
  setFilterValues: React.Dispatch<React.SetStateAction<any>>;
  onSubmit: (values: AccommodationsFilterFields) => void;
  saveWatchlist: (values: AccommodationsFilterFields) => void;
  loading: boolean;
};

const AccommodationsFilter: FC<AccommodationsFilterProps> = ({
  category,
  filterValues,
  setFilterValues,
  onSubmit,
  loading,
  saveWatchlist,
}) => {
  const { t } = useTranslation();
  const [_filterValues, _setFilterValues] = useState(filterValues);
  const cities = useSelector(selectCities);
  const subCategories = useSelector(selectSubCategoriesByParent(category.id));

  const accommodationsFilters = [
    radioField("city", t("FILTERS.city"), cities),
    radioField("sub_category", t("FILTERS.sub_category"), subCategories),
    numberField("price_min", t("FILTERS.price_min")),
    numberField("price_max", t("FILTERS.price_max")),
    numberField(
      "accommodation_square_meter_min",
      t("FILTERS.accommodation_square_meter_min")
    ),
    numberField(
      "accommodation_square_meter_max",
      t("FILTERS.accommodation_square_meter_max")
    ),
    radioField(
      "accommodation_bedrooms_min",
      t("FILTERS.accommodation_bedrooms_min"),
      ACCOMMODATION_BEDROOM_AMOUNT
    ),
    radioField(
      "accommodation_bedrooms_max",
      t("FILTERS.accommodation_bedrooms_max"),
      ACCOMMODATION_BEDROOM_AMOUNT
    ),
    radioField(
      "accommodation_sale_rent",
      t("FILTERS.accommodation_sale_rent"),
      ACCOMMODATION_SALE_RENT
    ),
    radioField(
      "accommodation_type",
      t("FILTERS.accommodation_type"),
      ACCOMMODATION_TYPES
    ),
  ];

  let initialValues = {};
  accommodationsFilters.map((filter) => {
    initialValues = {
      ...initialValues,
      [filter.name]: "",
    };
  });

  const handleSetFilterValues = (values: any) => {
    _setFilterValues(values);
    setFilterValues(values);
  };

  const filters = accommodationsFilters;
  const filterSelected = oneOfTheFiltersSelected(_filterValues);
  return (
    <Formik
      initialValues={_filterValues || { ...initialValues }}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit} className="filter_form">
            <>
              <FilterForm
                formik={formik}
                setFilterValues={handleSetFilterValues}
                filters={filters}
                category={"accommodation"}
              />
              {/*<label style={{ display: "flex", alignItems: "center" }}>*/}
              {/*  <Field type="checkbox" name="watchlist" style={{marginTop: 6}} />*/}
              {/*  <h3 className="filter-item__label">{t("LABELS.SAVE_TO_WATCHLIST")}</h3>*/}
              {/*</label>*/}
              <div className="filter__buttons-wrapper">
                <Button
                  type="submit"
                  styles={{ backgroundColor: Colors.GREEN }}
                  className="filter__submit-button"
                >
                  {loading ? (
                    <ClipLoader color="white" size={20} />
                  ) : (
                    t("BUTTONS.APPLY")
                  )}
                </Button>
                <Button
                  disabled={!filterSelected}
                  className="filter__reset-button"
                  onClick={() => {
                    formik.resetForm({values: initialValues});
                    handleSetFilterValues(null);
                    formik.submitForm().then();
                  }}
                >
                  {t("BUTTONS.CLEAR")}
                </Button>
              </div>
              <div className="filter__buttons-wrapper">
                <hr style={{width: '100%', marginBottom: 15}} />
                <Button
                    styles={{ backgroundColor: Colors.PINK }}
                    // disabled={!filterSelected}
                    className="filter__reset-button"
                    onClick={() => {
                      saveWatchlist(formik.values)
                    }}
                >
                  {t("LABELS.SAVE_TO_WATCHLIST")}
                </Button>
              </div>
            </>
          </form>
        );
      }}
    </Formik>
  );
};
export default AccommodationsFilter;
