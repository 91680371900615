// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-ad-page {
  width: 100%;
  max-width: 1180px;
  display: flex;
  padding: 20px 0;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .create-ad-page {
    padding: 10px 0 80px 0;
    flex-direction: row;
  }
}
.create-ad-page__title {
  width: 100%;
}
.create-ad-page__form {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  min-height: 300px;
}
@media (min-width: 992px) {
  .create-ad-page__form {
    padding: 20px;
  }
}
.create-ad-page .breadcrumbs {
  padding: 20px;
}
.create-ad-page.east > * {
  font-family: "Tahoma";
}`, "",{"version":3,"sources":["webpack://./src/pages/CreateAdPage/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;AADF;AAGE;EATF;IAUI,sBAAA;IACA,mBAAA;EAAF;AACF;AAEE;EACE,WAAA;AAAJ;AAGE;EACE,WAAA;EACA,eAAA;EACA,sBAAA;EACA,iBAAA;AADJ;AAGI;EANF;IAOI,aAAA;EAAJ;AACF;AAIE;EACE,aAAA;AAFJ;AAMI;EACE,qBAAA;AAJN","sourcesContent":["@import \"/src/styles/variables\";\n\n.create-ad-page {\n  width: 100%;\n  max-width: $max-width;\n  display: flex;\n  padding: 20px 0;\n  margin: 0 auto;\n  flex-direction: column;\n  flex-wrap: wrap;\n\n  @media (min-width: $breakpoint-tablet) {\n    padding: 10px 0 80px 0;\n    flex-direction: row;\n  }\n\n  &__title {\n    width: 100%;\n  }\n\n  &__form {\n    width: 100%;\n    padding: 0 20px;\n    box-sizing: border-box;\n    min-height: 300px;\n\n    @media (min-width: $breakpoint-tablet) {\n      padding: 20px;\n    }\n\n  }\n\n  .breadcrumbs {\n    padding: 20px;\n  }\n\n  &.east {\n    > * {\n      font-family: \"Tahoma\";\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
