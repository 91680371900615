import React, { useState, VFC } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Field, Formik } from "formik";
import TextInput from "../FormInputs/TextInput";
import * as Yup from "yup";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  fetchUserProfile,
  selectUserProfile,
  selectUserToken,
  selectUserType,
  updateUserInfo,
} from "~/store/slices/user";
import { UploadImages } from "~/components/FormInputs";
import Button from "~/components/Button";
import { addErrorMessage, addSuccessMessage } from "~/store/slices/snackbars";
import { ProfileInfo } from "~/api";
import { useDispatch } from "~/store";
import { ClipLoader } from "react-spinners";
import LoadingDots from "../LoadingDots";

export type ProfileBlockFields = {
  name: string;
  lastname: string;
  company_name: string;
  phone: string;
  logo?: string[];
  update_logo: boolean;
  delete_logo: boolean;
};

export type ProfileInfoProps = {
  onClick: (editable: boolean) => void;
};

const ProfileBlock: VFC<ProfileInfoProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserProfile);
  const [loading, setLoading] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const userType = useSelector(selectUserType);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(i18next.t("PLACEHOLDERS.NAME")),
    lastname: Yup.string().required(i18next.t("PLACEHOLDERS.SURNAME")),
    company_name:
      userType === "company"
        ? Yup.string().required(i18next.t("PLACEHOLDERS.BUSINESS_NAME"))
        : Yup.string(),
    phone: Yup.string().required(i18next.t("ERRORS.PHONE_REQ")).matches(
      /^[0-9 -+]+$/,
      i18next.t("ERRORS.PHONE_INVALID")
    ),
    logo: Yup.array(),
  });
  const initialValues: ProfileBlockFields = {
    name: user?.name,
    lastname: user?.lastname,
    company_name: user?.company_name,
    phone: user?.phone,
    logo: [],
    update_logo: true,
    delete_logo: false,
  };
  const handleSubmit = (values: ProfileBlockFields): void | Promise<any> => {
    setLoading(true);
    const body: ProfileInfo = {
      name: values.name,
      lastname: values.lastname,
      company_name: values.company_name,
      phone: values.phone,
      logo: values?.logo[0] || null,
      update_logo: !!values?.logo[0],
      delete_logo: values.delete_logo,
    };
    dispatch(updateUserInfo({ body, token: token }))
      .unwrap()
      .then((response) => {
        if (response.status === "ok") {
          dispatch(
            addSuccessMessage({
              title: t("PROFILE.TITLE"),
              message: t("PROFILE.SUCCESS_GENERIC_TEXT"),
            })
          );
        } else {
          dispatch(
            addErrorMessage({
              title: t("PROFILE.TITLE"),
              message: t("PROFILE.ERROR_MESSAGE"),
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        ReturnProfile();
      });
  };

  const ReturnProfile = () => {
    onClick(true);
    dispatch(fetchUserProfile(token));
  };

  if (!user) {
    return <LoadingDots />;
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
      >
        {(formik) => {
          return (
            <>
              <form onSubmit={formik.handleSubmit} className="profile-form">
                <div className="profile-block">
                  <h2 className="profile-block__header h2">
                    {t(`PROFILE.HEADER`)}
                  </h2>
                  <div className="profile-form__section-wrapper">
                    <div className="profile-form__section">
                      <Field
                        name="name"
                        component={TextInput}
                        label={t("LABELS.NAME")}
                        placeholder={t("PLACEHOLDERS.NAME")}
                      />
                      <Field
                        name="lastname"
                        component={TextInput}
                        label={t("LABELS.SURNAME")}
                        placeholder={t("PLACEHOLDERS.SURNAME")}
                      />
                      {userType === "company" && (
                        <Field
                          name="company_name"
                          component={TextInput}
                          label={t("LABELS.BUSINESS_NAME")}
                          placeholder={t("PLACEHOLDERS.BUSINESS_NAME")}
                        />
                      )}
                      <Field
                        name="phone"
                        component={TextInput}
                        label={t("LABELS.PHONE")}
                        placeholder={t("PLACEHOLDERS.PHONE")}
                      />
                    </div>

                    <div className="profile-form__section">
                      {userType === "company" && (
                        <>
                          <Field
                              name="logo"
                              component={UploadImages}
                              onChange={(files: string[]) => {
                                formik.setFieldValue("logo", files);
                                if(user?.logo && !hideLogo) {
                                  setHideLogo(true);
                                }
                              }}
                              hideDescription={true}
                              onRemove={(index: number) => {
                                const files = [...formik.values["logo"]];
                                files.splice(index, 1);
                                formik.setFieldValue("logo", files);
                              }}
                              title={t("FORM.UPLOAD_PHOTO_TITLE")}
                              maxImages={1}
                          />
                          {user?.logo && !hideLogo && (
                              <div className="upload-image__image-block">
                                <div className={'upload-image__thumbnail-wrapper'}>
                                  <img
                                      src={ user?.logo }
                                      className={ `upload-image__thumbnail label-text--disabled` }
                                  />
                                </div>
                                <button
                                    onClick={ () => {
                                      setHideLogo(true);
                                      formik.setFieldValue("logo", []);
                                      formik.setFieldValue('delete_logo', true);
                                      formik.setFieldValue('update_logo', false);
                                    } }
                                    type="button"
                                    className="upload-image__delete-button">
                                  <FontAwesomeIcon icon={ faTrash }/>
                                  { t("FORM.REMOVE") }
                                </button>
                              </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="profile-page__actions">
                  <Button type="submit">
                    {loading ? (
                      <ClipLoader color="white" size={20} />
                    ) : (
                      t("BUTTONS.SAVE_UPDATE")
                    )}
                  </Button>
                  <Button
                    className="profile-page__cancel-btn"
                    onClick={ReturnProfile}
                  >
                    {t("common.CANCEL")}
                  </Button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfileBlock;
