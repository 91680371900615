import { FieldProps } from "formik";
import React from "react";
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { selectLang } from '~/store/slices/user';
import "./styles.scss";

export interface DropdownProps<T> {
  items: T[];
  label: string;
  placeholder?: string;
  getLabel: (value: T) => string;
  onChange?: (value: T) => void;
  filterSelected: boolean;
  readOnly?: boolean
}

const DropdownInput = <T, >({
                              items,
                              label,
                              placeholder,
                              getLabel,
                              onChange,
                              form,
                              field,
                              filterSelected,
                              readOnly = false
                            }: DropdownProps<T> & FieldProps) => {

  const touched = form.touched[field.name];
  const error = form.errors[field.name];
  const lang = useSelector(selectLang);

  const handleOptionSelect = (option: T) => {

    form.setFieldValue(field.name, option.value);
    onChange && onChange(option.value);
  };

  const options = items.map((item, index) => {

    let name = item?.name;
    let value = item;
    switch (lang) {
      case 'kur':
        name = item?.name_kurdish
        break;
      case 'arab':
        name = item?.name_arabic;
        break;
    }
    if (!name) {
      name = item;
      value = item;
    }
    return {
      value: value,
      label: name
    }
  })

  const selectedOption = field?.value && options.find((item) => {
    return item?.value?.id === field?.value?.id || item?.value?.name === field?.value?.name
  });

  return (
    <div
      className={ `dropdown-input__wrapper` }
    >
      { label !== "" &&
       <span className={ `dropdown-input__label ${ readOnly ? 'label-text--disabled' : '' }` }>{ label }</span> }
      <Select
        defaultValue={ selectedOption }
        options={ options }
        onChange={ handleOptionSelect }
        classNamePrefix={"drpdwn"}
      />
      { touched && error && <div className="dropdown-input__error-message">{ error }</div> }
    </div>
  );
};
export default DropdownInput;
