import React, { FC } from "react";
import "./styles.scss";

export type ButtonProps = {
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  styles?: any;
  disabled?:boolean;
};

const Button: FC<ButtonProps> = ({
  className = "",
  children,
  onClick,
  type = "button",
  loading = false,
  styles,
  disabled = false
}) => {
  return (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      style={styles}
      className={`button ${className} ${loading ? "button--loading" : ""} ${disabled ? "button--disabled" : ""}`}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
