import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toBase64 } from "~/helpers";
import "./styles.scss";
import { addErrorMessage, addSuccessMessage } from '~/store/slices/snackbars';
import { useDispatch } from '~/store';

export type UploadImagesProps = {
  maxImages: number;
  title: string;
  description: string;
  onChange: (images: string[]) => void;
  onRemove: (index: number) => void;
  hideDescription?: boolean;
  readOnly?: boolean;
} & FieldProps<string[]>;

const acceptTypesImages = "image/jpg, image/jpeg, image/png, image/bmp, image/heic, image/heif";
const acceptTypesImagesAndVideo = "image/jpg, image/jpeg, image/png, image/bmp, image/heic, image/heif, video/x-msvideo, video/mpeg, video/mp4, video/x-flv, video/quicktime, video/x-ms-wmv, video/webm, video/x-matroska, video/mp2t, video/ogg, video/3gpp";
const maxAllowedSizeOfFile = 15 * 1024 * 1024;

const UploadImages: FC<UploadImagesProps> = ({
                                               maxImages,
                                               title,
                                               description,
                                               onChange,
                                               onRemove,
                                               field,
                                               form,
                                               hideDescription,
                                               readOnly
                                             }) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pickedFile, setPickedFile] = useState<File>();
  const [pickedFileUrl, setPickedFileUrl] = useState("");
  const [fieldList, setFieldList] = useState([]);
  const dispatch = useDispatch();


  const handleChange = (files: FileList) => {
    let moreFilesThanAllowed = false;
    let bigFilesRemoved = false;
    const validFiles: [string, File][] = [];

    //Filter out to large files
    Object.entries(files).forEach((entry, index) => {
      if (entry[1].size < maxAllowedSizeOfFile && validFiles.length < maxImages) {
        validFiles.push(entry);
      }
    });

    //Select the first file
    Object.entries(validFiles).forEach((element, index) => {
      const file = element.filter((file, key: number) => {
        return key === 1;
      });
      setPickedFile(validFiles[0]?.name);
      fieldList.push(file);
      setFieldList(fieldList);
    });
    if (validFiles.length < files.length) {
      bigFilesRemoved = true;
    }
    if (validFiles.length > maxImages) {
      moreFilesThanAllowed = true;
    }


    if (validFiles.length > 0) {
      setLoading(true);
      Promise.all(
        Array.from(files).filter((item) => {
          return validFiles.find(validItem => {
            return validItem[1].name === item.name
          })
        }).map((item) => {
          const imageInBase64 = toBase64(item);
          return imageInBase64
        })
      )
        .then((base64Files) => {
          onChange(base64Files);
          setPickedFileUrl(base64Files[0]);
        })
        .finally(() => {
          setLoading(false);
          if (bigFilesRemoved || moreFilesThanAllowed) {
            dispatch(addSuccessMessage({
              title: t('ACTION.SUCCESS'),
              message: t('ACTION.SUCCESS_REMOVED_SOME_FILES')
            }));
          }
        });
    } else {
      dispatch(addErrorMessage({
        title: t('ACTION.FAILURE'),
        message: t('ACTION.FAILURE_INVALID_FILES')
      }));
    }
  };


  const videoElement = (src:string)=>{
    return (
      <video controls width="64">
        <source src={ src }/>
      </video>
    )
  }

  const imageElement = (src: string) => {
    return (
      <img
        src={ src }
        className={ `upload-image__thumbnail ${ readOnly ? 'label-text--disabled' : '' }` }
      />
    );
  }

  return (
    <div className="upload-image">
      { title !== "" &&
       <span className={ `upload-image__title ${ readOnly ? 'label-text--disabled' : '' }` }>{ title }</span> }
      { !readOnly && !hideDescription &&
       <div className="upload-image__description">
         { field.value?.length || 0 }/{ maxImages } { description }
       </div> }

      { !readOnly &&
       <label className="upload-image__label">
         <div className="upload-image__upload-text">
           <p>
             { t("FORM.DRAG_FILE") }{ " " }
             <span className="upload-image__upload-text--highlighted">
              { t("FORM.UPLOAD_TEXT") }
            </span>
           </p>
           <p>{ maxImages > 1 ? t("FORM.RECOMMENDED_FORMAT_WITH_VIDEO") : t("FORM.RECOMMENDED_FORMAT") }</p>
           <p>{ t("FORM.MAX_SIZE") }</p>
         </div>
         <input
          type="file"
          name={ field.name }
          multiple
          accept={ maxImages > 1 ? acceptTypesImagesAndVideo : acceptTypesImages }
          className="upload-image__input"
          onChange={ (e) => {
            handleChange(e.currentTarget.files);
          } }
         />
       </label>
      }

      <div className="upload-image__thumbnails">

        { field.value?.map((src, key) => {

          const url = src?.thumbnail_url ? src?.thumbnail_url : src;
          return (
            <div key={ key } className="upload-image__image-block">
              <div className="upload-image__thumbnail-wrapper">
                { url.indexOf('data:video')>-1 ? videoElement(url) : imageElement(url) }
              </div>
              <div>
                { hideDescription && <p className="upload-image__file-name">{ pickedFile }</p> }
                { !readOnly &&
                 <button
                  onClick={ () => {
                    onRemove(key);
                  } }
                  type="button"
                  className="upload-image__delete-button">

                   <FontAwesomeIcon icon={ faTrash }/>

                   { t("FORM.REMOVE") }
                 </button>
                }

              </div>
            </div>
          )
        }) }
      </div>
      { form.touched[field.name] && form.errors[field.name] && (
        <div className="upload-image__error-message">
          { form.errors[field.name] }
        </div>
      ) }
    </div>
  );
};
export default UploadImages;
