import React, { useState, FC } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import ProfileBlock from "~/components/ProfileBlock";
import ProfileSettings from "~/components/ProfileSettings";
import ProfileInfo from "~/components/ProfileBlock/ProfileInfo";
import { useSelector } from 'react-redux';
import { selectUserAds, selectUserProfile } from '~/store/slices/user';
import MoreAds from '~/pages/ProductDetailsPage/MoreAds';

interface EditClickProps {
  toggleClick: () => void;
}

const ProfilePage: FC<EditClickProps> = () => {
  const {t} = useTranslation();
  const [actions, setActions] = useState<string>("profile");
  const [editable, setEditable] = useState<boolean>(false);

  const currentUser = useSelector(selectUserProfile);
  const currentUserAds = useSelector(selectUserAds);

  const blockSwitcher = () => {
    if (actions === "profile") {
      return <ProfileBlock onClick={ () => toggleClick() }/>;
    } else {
      return <ProfileSettings onClick={ () => toggleClick() }/>;
    }
  };

  const toggleClick = () => {
    setEditable(!editable);
  };

  const moreAdsSectionTitle  = currentUser.type==="private" ? t('ADS.MY'): t('ADS.MY_BUSINESS');

  return (
    <>
      <main className="profile-page">
        <div className="profile-page__breadcrumbs">
          <Breadcrumbs/>
        </div>

        { editable ? (
          <div className="profile-page__container">
            <div className="profile-page__actions-switcher">
              <button
                className={ `profile-page__actions-btn ${
                  actions === "profile" ? "profile-page__actions-btn_active" : ""
                }` }
                onClick={ () => setActions("profile") }
              >
                { t(`PROFILE.HEADER`) }
              </button>
              <button
                className={ `profile-page__actions-btn ${
                  actions === "settings" ? "profile-page__actions-btn_active" : ""
                }` }
                onClick={ () => setActions("settings") }
              >
                { t(`PROFILE.SETTINGS`) }
              </button>
            </div>

            { blockSwitcher() }
          </div>
        ) : (
          <div className="profile-page__container">
            <ProfileInfo sellerInfo={currentUser} onClick={ () => toggleClick() }/>
          </div>
        ) }

      </main>

      <MoreAds sectionTitle={moreAdsSectionTitle} products={ currentUserAds }/>
    </>


  )
    ;
};

export default ProfilePage;
