import React, {useEffect, useState} from "react";
import "./styles.scss";
import PlusAd from "~/assets/icons/plusAdd.svg";
import Heart from "~/assets/icons/heartWhite.svg";
import User from "~/assets/icons/userProfile.svg";
import Logo_NYs from "~/assets/logo/qeysari_svg.svg";

import NavItem from "./NavItem";
import {
  fetchDeleteUser,
  fetchLogOutData, selectLang,
  selectUserToken,
  selectAuthenticated,
  changeLang, selectUserProfile
} from "~/store/slices/user";
import i18n from "../../i18n/config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LanguageSwitcher from "./LanguageSwitcher";
import SearchBar from "../SearchBar";
import { useDevice } from "~/hooks/useDevice";
import {useHistory, useLocation} from "react-router-dom";
import SignOutIconBlack from "../../assets/icons/sign-out-black.svg";
import Hamburger from "../Hamburger";
import { addErrorMessage } from '~/store/slices/snackbars';

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const token = useSelector(selectUserToken);
  const device: string = useDevice();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const location = useLocation();
  const profile = useSelector(selectUserProfile);

  useEffect(() => {
    const lang = localStorage.getItem("lang") || 'en';
    changeLanguage(lang || "en");
    if(location.pathname === '/'){
      history.push(`/${lang || 'en'}`)
    }
  }, []);

  const manageProfile = async (type: string) => {
    try {
      type === "logout"
        ? dispatch(fetchLogOutData(token))
        : dispatch(fetchDeleteUser(token));
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  const changeLanguage = async (lan: string) => {
    i18n.changeLanguage(lan);
    dispatch(changeLang(lan));
  };

  const noCredits = profile && profile.credits===0 && isAuthenticated;
  const isHomePage = window.location.pathname === `/${lang}` || window.location.pathname === `/${lang}/`;

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__content_logo" onClick={() => history.push(`/${lang}`)}>
          {/*<img src={Logo_NY} width="230px" />*/}
          <Logo_NYs width={210} />
        </div>
        {device === "isDesktop" && !isHomePage && (
          <div className="header__search">
            <SearchBar selectCounty={false} />
          </div>
        )}
        {device === "isDesktop" ? (
          <ul className="header__nav-items">
            {/* <NavItem
                icon={<PlusAd />}
                label={t("common.navigationRegister")}
                to="/register"
              /> */}

            {/*{isAuthenticated && (*/}
              <>
                <NavItem
                  onClick={()=>{
                    if (noCredits){
                      dispatch(addErrorMessage({
                        title:t('ACTION.FAILURE'),
                        message:t('ACTION.FAILURE_NOT_ENOUGH_CREDITS')
                      }));
                    }
                  }}
                  icon={<PlusAd />}
                  label={t("common.navigationNewAd")}
                  to={`/${lang}/create-ad`}
                  className="nav-item--button"
                />
                <NavItem
                  icon={<Heart />}
                  label={t("common.navigationSaved")}
                  to={`/${lang}/saved`}
                />
                <NavItem
                  icon={<User />}
                  label={t("common.navigationProfile")}
                  to={`/${lang}/profile`}
                />
                {isAuthenticated ? (<NavItem
                  className="sign-out"
                  label={t("PROFILE.SIGN_OUT")}
                  icon={<SignOutIconBlack />}
                  onClick={() => manageProfile("logout")}
                  to={`/${lang}/`}
                />) : null}
              </>
            {/*)}*/}
            <LanguageSwitcher />
          </ul>
        ) : (
          <>
            {/*{isAuthenticated ? (*/}
              <>
                <Hamburger isAuthenticated={isAuthenticated} />
              </>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
