// import i18n from "i18next";
import i18n from "~/i18n/config";

export const ORDER_OPTIONS = [
  "created_desc",
  "created_asc",
  "price_desc",
  "price_asc",
];

export const OrderTitles = {
  created_desc: i18n.t("ORDER_OPTIONS.CREATED_DESC"),
  created_asc: i18n.t("ORDER_OPTIONS.CREATED_ASC"),
  price_desc: i18n.t("ORDER_OPTIONS.PRICE_DESC"),
  price_asc: i18n.t("ORDER_OPTIONS.PRICE_ASC"),
};

export type OrderType = typeof ORDER_OPTIONS[number];
