import React, { FC, VFC } from "react";
import Logo from "~/assets/logo/main-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Logo_NYs from "~/assets/logo/qeysari_svg.svg";

export type FullscreenModalHeaderProps = {
};

const FullscreenModalHeader: VFC<FullscreenModalHeaderProps> = ({
}) => {
  return (
    <div className="fullscreen-modal__header">
        <Logo_NYs width={210} />
    </div>
  );
};
export default FullscreenModalHeader;
