import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FieldProps } from "formik";
import React, { InputHTMLAttributes, useState, VFC } from "react";
import "./styles.scss";

export type TextInputProps = {
  label?: string;
  readOnly ?:boolean;
} & FieldProps &
  InputHTMLAttributes<HTMLInputElement>;

const TextInput: VFC<TextInputProps> = ({
  field,
  type,
  placeholder,
  className = "",
  form,
  label = "",
  readOnly
}) => {
  const { touched, errors } = form;
  const haveError = touched[field.name] && errors[field.name];
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
    <div className={`text-input__wrapper`}>
      <label className="text-input__label">
        {label !== "" && <span className={`text-input__label-text ${readOnly ? 'label-text--disabled' : ''}`}>{label}</span>}
          <input
          disabled={readOnly}
          readOnly={readOnly}
          className={`text-input ${className} ${
            haveError ? "text-input--error" : ""
          }`}
          type={showPassword && type === "password" ? "text" : type}
          placeholder={placeholder}
          {...field}
        />
      </label>
      {type === "password" && (
        <div className="text-input__toggle-password">
          <FontAwesomeIcon onClick={() => setShowPassword(!showPassword)} className="text-input__toggle-password-icon"
          icon={faEye}
          />
        </div>
      )}
    </div>
    {haveError && (
      <div className="text-input__error-message">{errors[field.name]}</div>
    )}
    </>
  );
};
export default TextInput;
