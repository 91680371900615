import React, {useState, VFC} from "react";
import "./styles.scss";
import Logo_NYs from "~/assets/logo/qaysari-new.svg";
import PlayStoreImage from "~/assets/images/get-it-on-google-play.png";
import AppStoreImage from "~/assets/images/available-on-the-app-store.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang, selectAuthenticated} from "~/store/slices/user";

export type FooterLink = {
  text: string;
  to: string;
  isAuth?: boolean;
};

const Footer: VFC = () => {
  const [showAppStore, _] = useState<boolean>(false);
  const { t } = useTranslation();
  const lang = useSelector(selectLang);
  const isAuthenticated = useSelector(selectAuthenticated);
  const linksFirstSection: FooterLink[] = [
    { to: `/${lang}/register`, text: t("FOOTER.LINKS.CREATEACCOUNT"), isAuth: false },
    { to: `/${lang}/about-us`, text: t("FOOTER.LINKS.ABOUTUS") },
    // { to: "/how-app-works", text: t("FOOTER.LINKS.HOWAPPWORKS") },
    { to: `/${lang}/faq`, text: t("FOOTER.LINKS.FAQ") },
  ];
  const linksSecondSection: FooterLink[] = [
    { to: `/${lang}/terms-of-use`, text: t("FOOTER.LINKS.TERMSOFUSE") },
    { to: `/${lang}/privacy-policy`, text: t("FOOTER.LINKS.PRIVACYPOLICY") },
    { to: `/${lang}/cookies`, text: t("FOOTER.LINKS.COOKIES") },
  ];
  return (
    <footer className="footer">
      <div className="footer__sections">
        <div className="footer__logo-section">
          <Logo_NYs width={180} />
          <p className="footer__logo-subtext">{t("FOOTER.LOGOSUBTEXT")}</p>
        </div>
        <ul className="footer__link-section">
          {linksFirstSection.map(({ to, text, isAuth }, index) => {
            return isAuth === undefined || isAuthenticated === isAuth ? (
             <li key={index} className="footer__link-item">
              <Link className="footer__link" to={to}>
                {text}
              </Link>
            </li>
          ) : null})}
        </ul>
        <ul className="footer__link-section">
          {linksSecondSection.map(({ to, text }, index) => (
            <li key={index} className="footer__link-item">
              <Link className="footer__link" to={to}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
        <div className="footer__appstore-section">
          {showAppStore ? <><img
            className="footer__appstore-image"
            src={PlayStoreImage}
            alt="get it in play store"
          />
          <img
            className="footer__appstore-image"
            src={AppStoreImage}
            alt="available on the app store"
          /></> : null}
        </div>
      </div>
      <div className="footer__copyright" dangerouslySetInnerHTML={{
        __html: t("FOOTER.COPYRIGHT", {linkToPrivacy: `/${lang}/terms-of-use`, currentYear: new Date().getFullYear()})
      }} />
    </footer>
  );
};

export default Footer;
