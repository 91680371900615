import { VehicleBrand, VehicleModel } from "~/api/vehicles";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchVehicleModels as _fetchVehicleModels,
  fetchVehicleBrands as _fetchVehicleBrands,
} from "~/api";

const token = localStorage.getItem("auth");
export const fetchVehicleBrands = createAsyncThunk(
  "vehicles/fetchVehicleBrands",
  async () => {
    const { data } = await _fetchVehicleBrands(token);
    return data.data;
  }
);

export const fetchVehicleModels = createAsyncThunk(
  "vehicles/fetchVehicleModels",
  async (id: number) => {
    const { data } = await _fetchVehicleModels(id, token);
    const models = data.data.map((model) => ({ ...model, parentId: id }));
    return models as VehicleModel[];
  }
);

export const fetchAllVehicleModels = createAsyncThunk(
  "vehicles/fetchAllVehicleModels",
  async (brands: VehicleBrand[]) => {
    const allModels = brands.flatMap(async (brand) => {
      const { data } = await _fetchVehicleModels(brand.id, token);
      return data.data.map((model) => ({
        ...model,
        parentId: brand.id,
      }));
    });
    const models = await (
      await Promise.all(allModels)
    ).flatMap((model) => model);
    return models as VehicleModel[];
  }
);
