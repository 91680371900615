import React, { MouseEventHandler, ReactNode, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchSavedAdd, fetchSavedDelete, Media, PriceHistory } from "~/api";

import { Categories } from "~/consts/data/categories";
import { formatPrice, getCurrentCategory } from "~/helpers";
import renderCategory from "~/helpers/renderCategory";
import { selectCategories } from "~/store/slices/categories";
import LikeIcon from "../../assets/icons/like-icon.svg";
import RedLikeIcon from "../../assets/icons/like-red-icon.svg";

import ProductCarousel from "../ProductCarousel";
import "./styles.scss";
import { useDevice } from "~/hooks/useDevice";
import { selectLang, selectUserToken, selectAuthenticated } from "~/store/slices/user";
import ResponsiveImage from '~/components/ResponsiveImage';
import Button from '~/components/Button';
import { t } from 'i18next';
import { addErrorMessage } from '~/store/slices/snackbars';
import { useDispatch } from '~/store';

export type ProductCardProps = {
  logo: string;
  id: number;
  title: string;
  description: string;
  location: string;
  moreAds?: boolean;
  date: string;
  priceTag: string;
  className?: string;
  categorySlug?: string;
  media?: Media[];
  icon?: ReactNode;
  saved_ad?: boolean;
  user_id: number;
  handleDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => void;
  status: string;
  price_history: PriceHistory[];
};
const ProductCard: VFC<ProductCardProps> = ({
                                              logo,
                                              id,
                                              title,
                                              description,
                                              location,
                                              date,
                                              priceTag,
                                              categorySlug = "all",
                                              className = "",
                                              media,
                                              icon, saved_ad,
                                              moreAds,
                                              handleDelete,
                                              status,
                                              price_history

                                            }) => {
  const categories = useSelector(selectCategories);
  const {category: _categoryName} = useParams<{ category?: Categories }>();
  const categoryName = !_categoryName ? "all" : _categoryName;
  const currentCategory = getCurrentCategory(categories, categoryName);
  const device: string = useDevice();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const oldPrice = price_history && price_history?.length > 0 && price_history[0].price;
  const token = useSelector(selectUserToken);
  const authenticated = useSelector(selectAuthenticated);
  const [favorite, setFavorite] = useState(saved_ad);
  const dispatch = useDispatch();

  const removeFromFavorites = async (e) => {
    e.stopPropagation();
    if (!authenticated) {
      history.push(`/${ lang }/register`);
      return;
    }
    fetchSavedDelete({ad_id: id, token}).then((response) => {
      if (response.data.status==="ok"){
        setFavorite(false);
      } else {
        dispatch(addErrorMessage({
          title:t('ACTION.FAILURE'),
          message:t('ACTION.FAILURE_GENERIC_TEXT')
        }))
      }
    });
  }

  const addToFavorites = async (e) => {
    e.stopPropagation();
    if (!authenticated) {
      history.push(`/${ lang }/register`);
      return;
    }
    fetchSavedAdd({ad_id: id, token}).then((response) => {
      if (response.data.status==="ok"){
        setFavorite(true);
      } else{
        dispatch(addErrorMessage({
          title:t('ACTION.FAILURE'),
          message:t('ACTION.FAILURE_GENERIC_TEXT')
        }))
      }
    });
  }

  return (
    <div
      className={ `product-card product-status-${ status } ${ className }` }
      onClick={ () =>
        history.push({
          pathname: `/${ lang }/products/${ categorySlug }/${ id }`,
          state: {logo},
        })
      }
    >
      { currentCategory.name === "Accommodation" ? (
        <div style={ {position: "relative"} }>
          <ProductCarousel
            mobileStyle={ !moreAds ? {height: 128, width: 128} : {} }
            desktopStyle={ {height: 320, width: 480} }
            accomodationMark={ false }
            showNavigation={ device !== "isMobile" }
            media={ media?.map((item) => {
              return item.thumbnail_url;
            }) }
          />
          { logo && (
            <div className="product-card__logo-accomodationCard">
              <img className="product-card__logoImg" src={ logo }/>
            </div>
          ) }
        </div>
      ) : (
        <div className="product-card__image-wrapper">
          { !!media.length &&
           <ResponsiveImage
            className={ 'product-card__image' }
            fullImageUrl={ media[0]?.url ? media[0]?.url : media[0]?.thumbnail_url }
            alt={ title }
            title={ title }
            thumbnailImageUrl={ media[0]?.thumbnail_url }
            layout={ 'list' }
           />
          }

          { logo && (
            <div className="product-card__logo-mainCard">
              <img className="product-card__logoImg" src={ logo }/>
            </div>
          ) }
        </div>
      ) }
      { moreAds && <h3 className="product-card__title">{ title }</h3> }
      <div
        className={
          currentCategory.name === "Accommodation"
            ? "product-card__acomodation-content"
            : "product-card__content"
        }
      >
        <div className="product-card__product-information">
          <div className="product-card__main-information">
            { !moreAds && <h3 className="product-card__title">{ title }</h3> }
            <div className="product-card__description">
              <p className="product-card__location">{ location }</p>
              <p className="product-card__description">{ description }</p>
              <p className="product-card__description">ID: {id}</p>
            </div>
          </div>
          <div className={ `product-card__price_block ` }>
            <p
              className={ `${ "product-card__price" } ${
                currentCategory.name === "Accommodation" &&
                device !== "isMobile"
                  ? "product-card__accomodationPrice"
                  : ""
              } status-${ status } category-${ currentCategory.name } ${ device == "isMobile" ? "mobile" : "desktop" }` }
              style={ status === "sold" ? {} : {
                color:
                  currentCategory.name === "Accommodation" &&
                  device !== "isMobile"
                    ? "white"
                    : renderCategory(currentCategory.slug).categoryColor,
              } }
            >
              { formatPrice(priceTag) } { !!oldPrice &&
             <span className={ "old-price" }>{ formatPrice(oldPrice) }</span> }
            </p>


            { device === "isMobile" && !moreAds && (
              <div className="product-card__date">{ date }</div>
            ) }
          </div>
        </div>

        { (device === "isTablet" || device === "isDesktop" || moreAds) && (
          <div className="product-card__data-like-section">
            <div className="product-card__date">{ date }</div>
            { icon ? (
              <button
                onClick={ (e) => handleDelete(e, id) }
                className="product-card__icon"
              >
                { icon }
              </button>
            ) : favorite ? (
              <>
                <RedLikeIcon onClick={ removeFromFavorites } className="product-card__like-icon"/>
              </>
            ) : (
              <>
                <LikeIcon onClick={ addToFavorites } className="product-card__like-icon"/>
              </>

            ) }
          </div>
        ) }


      </div>
      { status === "sold" &&
       <div className={ "button-row p-20" }>
         <Button disabled={ true }>{ t('BUTTONS.SOLD') }</Button>
       </div>
      }

      { status === 'pending' &&
       <>
         <Button loading={ false } className={ "button--pending" }>
           { t('BUTTONS.PENDING') }
         </Button>
       </>
      }
    </div>
  );
};
export default ProductCard;
