import { FieldProps } from "formik";
import React, { InputHTMLAttributes, VFC } from "react";
import "./styles.scss";

export type TextAreaInputProps = {
  label?: string;
  readOnly?: boolean
} & FieldProps &
  InputHTMLAttributes<HTMLTextAreaElement>;

const TextAreaInput: VFC<TextAreaInputProps> = ({
  field,
  placeholder,
  className = "",
  form,
  label = "",
  readOnly=false
}) => {
  const { touched, errors } = form;
  const haveError = touched[field.name] && errors[field.name];
  return (
    <div className={`text-area-input__wrapper`}>
      <label className="text-area-input__label">
        {label !== "" && <span className={`text-area-input__label-text ${readOnly ? 'label-text--disabled' : ''}`}>{label}</span> }
        <textarea
          readOnly={readOnly}
          disabled={readOnly}
          className={`text-area-input ${className} ${
            haveError ? "text-area-input--error" : ""
          }`}
          placeholder={placeholder}
          {...field}
        />
      </label>
      {haveError && (
        <div className="text-area-input__error-message">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};
export default TextAreaInput;
