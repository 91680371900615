import {
  changePassword,
  fetchSellerProfileApi,
  LoginModel,
  RegistrationModel,
  resetPassword,
  registerUser as _registerUser,
  loginUser as _loginUser,
  fetchUserProfile as _fetchUserProfile,
  logoutUser,
  deleteUser,
  fetchProfileSettingsUpdate,
  fetchProfileUpdate,
  ProfileSettingsModel,
  ProfileInfo,
} from "~/api";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import api from '~/api/axios';
import { addErrorMessage } from '~/store/slices/snackbars';
import { t } from 'i18next';

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (user: RegistrationModel, {dispatch}) => {
    const registration = await _registerUser(user);
    if (registration.data.status === "error") {
      if (registration.data.system_message==="Email already exists"){
        dispatch(
          addErrorMessage({
            message: t('REGISTER.ALREADY_EXISTS'),
            title: t('REGISTER.FAILED')
          })
        );
      }
      throw new Error(t('REGISTER.ALREADY_EXISTS'));
    }
    const {email, password} = user;
    const credentials = {email, password, firebase_token: "-"};
    dispatch(loginUser(credentials));
    return registration.data;
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (credentials: LoginModel, {dispatch}) => {
    if (!credentials.firebase_token) {
      credentials.firebase_token = "-";
    }
    const {data} = await _loginUser(credentials);

    if (Number.isInteger(data.user_id))
      dispatch(fetchUserProfile(data.user_token));
    return data;
  }
);

export const setToken = createAction(
  "user/SET_TOKEN",
  (token: string | null) => ({
    payload: token,
  })
);

export const setSocialLogin = createAction(
  "user/social-login",
  (isSocial: boolean) => ({
    payload: isSocial,
  })
);

export const setProfileType = createAction(
  "user/profile-type",
  (profileType: "private" | "company") => ({
    payload: profileType,
  })
);

export const logout = createAction("user/logout", (token: string) => ({
  payload: token,
}));

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (token: string) => {
    const {data} = await _fetchUserProfile(token);
    const {data: profile, ads} = data;
    return {profile, ads};
  }
);

export const changeLang = createAction("user/changeLang", (lang) => ({
  payload: lang,
}));

export const fetchLogOutData = createAsyncThunk(
  "user/logout",
  async (token: null | string) => {
    const {data} = await logoutUser(token);
    return data;
  }
);

export const fetchDeleteUser = createAsyncThunk(
  "user/delete",
  async (token: null | string) => {

    const {data} = await deleteUser(token);
    return data;
  }
);

export type UpdateUserSettingArgs = {
  body: ProfileSettingsModel;
  token: string | null;
};

export type UpdateUserInfoArgs = {
  body: ProfileInfo;
  token: string | null;
};

export const updateUserSettings = createAsyncThunk(
  "user/profile/settings/update",
  async ({body, token}: UpdateUserSettingArgs) => {
    const {data} = await fetchProfileSettingsUpdate(body, token);
    return data;
  }
);

export const updateUserInfo = createAsyncThunk(
  "user/profile/update",
  async ({body, token}: UpdateUserInfoArgs) => {
    const {data} = await fetchProfileUpdate(body, token);
    return data;
  }
);

export const fetchSellerProfile = createAsyncThunk(
  "user/GET_SELLER_PROFILE",
  async ({token, profileId, categoryId}: { token: string; profileId: number, categoryId: number | undefined }) => {
    const {data} = await fetchSellerProfileApi(token, profileId, categoryId);
    return data;
  }
);

export const resetUserPassword = createAsyncThunk(
  "user/RESET_PASSWORD",
  async ({email}: { email: string; }) => {
    const {data} = await resetPassword(email);
    return data;
  }
);

export const changeUserPassword = createAsyncThunk(
  "user/CHANGE_PASSWORD",
  async ({password, hash}: { password: string; hash: string }) => {
    const {data} = await changePassword({password, hash});
    return data;
  }
);

export const resetSuccessMsg = createAction(
  "user/RESET_MSG",
  () => {
    return {
      payload: true
    }
  }
);

export const fetchVerifyEmail = (email: string, token:string) =>{
  return api.get(`/verify-email/${token}/${email}`);
}
