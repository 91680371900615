// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noResult-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3em 1em;
  padding: 1em;
}
@media (min-width: 992px) {
  .noResult-page {
    margin: 10em 1em;
  }
}
.noResult-page__specialMark-style {
  margin: 1em;
}
@media (min-width: 992px) {
  .noResult-page__specialMark-style {
    margin: 2.8em;
  }
}
.noResult-page__title {
  font-size: 1.5rem;
  line-height: 20%;
  text-align: center;
  font-family: "Dubai-Medium";
}
@media (min-width: 992px) {
  .noResult-page__title {
    font-size: 2.5rem;
  }
}
.noResult-page__text {
  font-family: "Tajawal-Light";
  line-height: 1.5em;
  font-size: 1rem;
  text-align: center;
  margin: 0.8em;
  max-width: 22em;
}
@media (min-width: 992px) {
  .noResult-page__text {
    font-size: 1.4rem;
  }
}
.noResult-page__noResultBtn {
  margin: 1em;
}
.noResult-page__logo {
  margin: 2.3em;
}
@media (min-width: 992px) {
  .noResult-page__logo {
    width: 180px;
    height: 180px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/NoResultPage/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;AADF;AAGE;EAPF;IAQI,gBAAA;EAAF;AACF;AAEE;EACE,WAAA;AAAJ;AACI;EAFF;IAGI,aAAA;EAEJ;AACF;AACE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,2BAAA;AACJ;AACI;EANF;IAOI,iBAAA;EAEJ;AACF;AACE;EACE,4BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EAEA,eAAA;AAAJ;AAEI;EATF;IAUI,iBAAA;EACJ;AACF;AAEE;EAEE,WAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAII;EAHF;IAII,YAAA;IACA,aAAA;EADJ;AACF","sourcesContent":["@import \"/src/styles/variables\";\n\n.noResult-page {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin: 3em 1em;\n  padding: 1em;\n\n  @media (min-width: $breakpoint-tablet) {\n    margin: 10em 1em;\n  }\n\n  &__specialMark-style {\n    margin: 1em;\n    @media (min-width: $breakpoint-tablet) {\n      margin: 2.8em;\n    }\n  }\n\n  &__title {\n    font-size: 1.5rem;\n    line-height: 20%;\n    text-align: center;\n    font-family: \"Dubai-Medium\";\n\n    @media (min-width: $breakpoint-tablet) {\n      font-size: 2.5rem;\n    }\n  }\n\n  &__text {\n    font-family: \"Tajawal-Light\";\n    line-height: 1.5em;\n    font-size: 1rem;\n    text-align: center;\n    margin: 0.8em;\n\n    max-width: 22em;\n\n    @media (min-width: $breakpoint-tablet) {\n      font-size: 1.4rem;\n    }\n  }\n\n  &__noResultBtn {\n    // min-width: 221px;\n    margin: 1em;\n  }\n\n  &__logo {\n    margin: 2.3em;\n\n    @media (min-width: $breakpoint-tablet) {\n      width: 180px;\n      height: 180px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
