import { Category, City, SubCategory } from "~/api";

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const getCurrentCategory = (
  categories: Category[],
  categoryName?: string,
  categoryId?: number
) => {
  if (categoryName === "all" || categoryId === 0) {
    return {id: null, name: "All", slug: "all"} as Category;
  }
  const currentCategory = categories.find(
    (_category) =>
      _category.name.toLowerCase() === categoryName.toLowerCase() ||
      _category.name.toLowerCase().startsWith(categoryName.toLowerCase()) ||
      categoryName.toLowerCase().startsWith(_category.name.toLowerCase()) ||
      categoryId === _category.id
  );
  return {
    ...currentCategory,
    slug: categoryName,
  } as Category;
};

export const toBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });

export const getCategoryNameById = (id: number) => {
  switch (id) {
    case 1:
      return "vehicles"
    case 2:
      return "accommodations"
    case 3:
      return "electronics"
    case 4:
      return "other"
    default:
      return "all"
  }
}

export const getDefaultLanguage = () => {
  return localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
}

export const getCurrentCity = (cities: City[], cityId: number) => {
  return cities.find((city) => {
    return city.id === cityId
  });
}

export const getCurrentSubCategory = (
  subCategories: SubCategory[],
  subCategoryName?: string,
  subCategoryId?: number,
  parentCategoryId?: number
) => {
  const currentSubCategory = subCategories.find(
    (_category) =>
      _category.name.toLowerCase() === subCategoryName.toLowerCase() ||
      _category.name.toLowerCase().startsWith(subCategoryName.toLowerCase()) ||
      subCategoryName.toLowerCase().startsWith(_category.name.toLowerCase()) ||
      subCategoryId === _category.id
  );

  if (currentSubCategory) {
    return {
      ...currentSubCategory,
      slug: subCategoryName,
      parentId: parentCategoryId
    } as SubCategory;
  }
  return null;
};

export const formatPrice = (value:number|string) => {
  return USDollar.format(parseInt(value));
}

export const oneOfTheFiltersSelected = (filterValues) => {
  const filterSelected =  filterValues && Object.keys(filterValues).find((filterKey)=>{
    return filterValues[filterKey];
  });

  return !!filterSelected;
}
