import { FieldProps } from "formik";
import React, { InputHTMLAttributes, VFC } from "react";
import "./styles.scss";
import {Link} from "react-router-dom";

export type CheckboxInputProps = {
  label?: string;
  linkText?: string;
  link?: string;
  target?:string;
  readOnly:boolean;
} & FieldProps &
  InputHTMLAttributes<HTMLInputElement>;

const CheckboxInput: VFC<CheckboxInputProps> = ({
  field,
  className = "",
  form,
  label = "",
  linkText,
  link,
  target,
                                                  readOnly
}) => {
  const { touched, errors } = form;
  const haveError = touched[field.name] && errors[field.name];
  return (
    <div className={`checkbox-input__wrapper ${className}`}>
      <label className="checkbox-input__label">
        <input
          disabled={readOnly}
          type="checkbox"
          className={`checkbox-input ${
            haveError ? "checkbox-input--error" : ""
          }`}
          {...field}

          checked={!!field.value}
        />
        {label !== "" ? (
          <span className={`checkbox-input__label-text ${readOnly ? 'label-text--disabled' : ''}`}>
            {label} {link ? <Link target={target} to={link}>{linkText}</Link>: ''}
          </span>
        ) : null}
      </label>
      {haveError && (
        <div className="checkbox-input__error-message">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};
export default CheckboxInput;
