import { OrderType } from "~/consts/data/order";
import { RootState } from "~/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Product,
  ProductRequestParams,
  fetchLatestProducts as _fetchLatestProducts,
  fetchProductsWithParams as _fetchProductsWithParams,
  fetchProductDetails as _fetchProductDetails,
  createAd as _createAd,
  fetchSavedProductsOrder as _fetchSavedProductsOrder,
  ProductCreateModel,
  ProductGet,
  SavedProductsOrderParams,
} from "~/api/product";
import { cloneDeep } from "lodash";
import { Filter } from "./reducer";

export const fetchLatestProducts = createAsyncThunk<Product[]>(
  "products/fetchLatestProducts",
  async () => {
    const {
      data: { ads },
    } = await _fetchLatestProducts();
    return ads;
  }
);

export type FetchFilterProductsArgs = {
  categoryId?: number;
  filter?: Filter;
  order?: OrderType;
  search?: string;
};

export const fetchFilteredProducts = createAsyncThunk<
  Product[],
  FetchFilterProductsArgs,
  { state: RootState }
>(
  "products/fetchFilteredProducts",
  async ({ categoryId, filter, order, search }, { getState }) => {
    const validatedFilter: any = cloneDeep(filter);

    for (const filterName in validatedFilter) {
      const prop = validatedFilter[filterName];
      if (!prop) {
        delete validatedFilter[filterName];
      }
      if (typeof prop !== "string") {
        if (prop?.id >= -1 || typeof prop?.id === "string") {
          validatedFilter[filterName] = prop.id;
        } else {
          delete validatedFilter[filterName];
        }
      }
    }

    const props: ProductRequestParams = {
      ...validatedFilter,
      category: categoryId,
      order,
      search
    };

    const {
      data: { ads },
    } = await _fetchProductsWithParams(props)
    return ads;
  }
);

export const fetchProductDetails = createAsyncThunk<any, ProductGet>(
  "products/fetchProductDetails",
  async ({productId, token}) => {
    const { data: {data, more_ads} } = await _fetchProductDetails(productId, token);
    return {...data, more_ads};
  }
);

export const createAd = createAsyncThunk<any, ProductCreateModel>(
  "products/createAd",
  async (productId) => {
    const { data } = await _createAd(productId);
    if (data.status === "error") {
      throw data.message;
    }
    return data;
  }
);


export const fetchSavedProduct = createAction(
  "products/saved-product",
  (savedProduct) => ({
    payload: savedProduct,
  })
);

export const removeSavedProduct = createAction("products/delete", (id) => ({
  payload: id,
}));

export const fetchSavedFilters = createAction(
  "/ad/filters/list",
  (savedFilters) => ({
    payload: savedFilters,
  })
);

export const removeSavedFilters = createAction("/ad/filters/delete", (id) => ({
  payload: id,
}));

