import React, { useEffect, useState, VFC } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import "../ProfilePage/styles.scss";
import { useTranslation } from "react-i18next";
import WatchlistTab from "./WatchList";
import SavedTab from "./Saved";
import { OrderType } from "~/consts/data/order";
import { useDispatch, useSelector } from "react-redux";
import { fetchSavedProduct, selectSavedProducts } from "~/store/slices/products";
import { addErrorMessage } from "~/store/slices/snackbars";
import { fetchSavedProductsOrder } from "~/api";
import {selectLang} from "~/store/slices/user";

const SavedProductsPage: VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [actions, setActions] = useState<string>("ads");

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [order, setOrder] = useState<OrderType>("created_desc");
  const savedProducts = useSelector(selectSavedProducts);
  const history = useHistory();
  const lang = useSelector(selectLang);

  useEffect(() => {
    if (Object.keys(savedProducts).length) {
      savedProductOrder();
    }
  }, [order]);

  useEffect(() => {
    const pageHistory = history.location.state;
    if(!pageHistory?.tab){
      setActions(history.location.pathname === `/${lang}/watchlist` ? 'watchlist' : 'ads');
      return false;
    }
    console.log('pageHistory location :: ', pageHistory, history.location.pathname);
    setActions(pageHistory?.tab);
  }, [location]);

  const savedProductOrder = async () => {
    setIsFetchingData(true);
    const token = localStorage.getItem("auth");

    try {
      const {data} = await fetchSavedProductsOrder({
        order, token})

      if (data.status === "ok") {
        dispatch(fetchSavedProduct(data.ads))
        setIsFetchingData(false);
      } else {
        addErrorMessage({
          message: "Failed",
          title: data.message,
        });
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  }

  //disabled doe to implementation is not  working
  const disableMenu = false;

  return (
    <main className="saved-product-page">
      <Breadcrumbs className="saved-product-page__breadcrumbs" />
      { !disableMenu &&
       <div className="profile-page__actions-switcher">
         <button
          className={ `profile-page__actions-btn ${
            actions === "ads" ? "profile-page__actions-btn_active" : ""
          }` }
          onClick={ () => {
            history.push(`/${ lang }/saved`, {tab: 'ads'});
            setActions("ads");
          }}
         >
           { t(`SAVED.ADS`) }
         </button>
         <button
          className={ `profile-page__actions-btn ${
            actions === "watchlist" ? "profile-page__actions-btn_active" : ""
          }` }
          onClick={ () => {
            history.push(`/${ lang }/watchlist`, {tab: 'watchlist'});
            setActions("watchlist");
          }}
         >
           { t(`SAVED.WATCHLIST`) }
         </button>
       </div>
      }


      { actions === "ads" && <SavedTab order={ order }
                                       setOrder={ setOrder } savedProducts={ savedProducts }/> }
      { actions === "watchlist" && <WatchlistTab/> }
    </main>
  );
};

export default SavedProductsPage;
