import { FormikProps } from "formik";
import React, { VFC } from "react";
import Dropdown from "../Dropdown";
import NumberField from "../NumberField";
import Radio, { Option } from "../Radio";
import i18n from "i18next";
import useForceUpdate from "~/hooks/useForceUpdate";
import { City, SubCategory, VehicleBrand, VehicleModel } from "~/api";
import { VehicleFuel, VehicleGearbox } from "~/consts/data/vehicles";

export interface RadioItem {
  id: string | number;
  name: string;
}

export type FilterFields = {
  category_id: string,
  vehicle_brand?: VehicleBrand;
  vehicle_model?: VehicleModel;
  city?: City;
  sub_category?: SubCategory;
  price_min?: number;
  price_max?: number;
  vehicle_year_min?: number;
  vehicle_year_max?: number;
  vehicle_milage_min?: number;
  vehicle_milage_max?: number;
  vehicle_gearbox?: VehicleGearbox;
  vehicle_fuel?: VehicleFuel;
  accommodation_square_meter_min?: number;
  accommodation_square_meter_max?: number;
  accommodation_bedrooms_min?: number;
  accommodation_bedrooms_max?: number;
  accommodation_sale_rent?: string;
  accommodation_type?: string;
  // watchlist: boolean;
};

export interface RadioFilterField {
  name: string;
  label: string;
  items: Option[];
  type: string;
  parent?: string;
  placeholder?: string;
}
export interface NumberFilterField {
  name: string;
  label: string;
  min: number;
  max: number;
  type: string;
  parent?: string;
}

export const radioField = (
  name: string,
  label: string,
  items: readonly RadioItem[],
  placeholder?: string,
  parent?: string
): RadioFilterField => {
  const languagePrefix = i18n.language === 'arab' ? '_arabic' : i18n.language === 'kur' ? '_kurdish' : '';
  return {
    name,
    label,
    items: [
      {
        label: i18n.t("CATEGORIES.ALL"),
        name: "all",
        id: -1,
        value: {},
      } as Option,
      ...items.map(
          (option) => {
            const optionLabel = option[`name${languagePrefix}`] !== undefined ? option[`name${languagePrefix}`] : option.name;
            return {
              label: optionLabel,
              name: name,
              id: option.id,
              value: option,
            } as Option
          }
      ),
    ],
    type: "radio",
    parent,
    placeholder,
  };
}

export const numberField = (
  name: string,
  label: string,
  min?: number,
  max?: number
): NumberFilterField => ({ name, label, min, max, type: "number" });

export type FilterFormProps = {
  formik: FormikProps<any>;
  filters: (NumberFilterField | RadioFilterField)[];
  setFilterValues: (value: any) => void;
  category?: string;
};

const FilterForm: VFC<FilterFormProps> = ({
  filters,
  formik,
  setFilterValues,
  category,
}) => {
  const forceUpdate = useForceUpdate();
  return (
    <>
      {filters.map((_filter) => {
        if (_filter.type === "radio") {
          const filter = _filter as RadioFilterField;

          if (!filter.items.length) {
            return null;
          }

          return (
            <Dropdown
              id={filter.name}
              key={`${filter.name}`}
              label={filter.label}
              value={formik?.values?.[filter?.name]?.name}
              name={`${filter.name}`}
              placeholder={filter.label}
              category={category}
            >

              <Radio
                items={filter.items}
                onClick={(value) => {

                  forceUpdate();
                  formik.setFieldValue(filter.name, value);
                  filters
                    .filter((_filter) => _filter?.parent === filter.name)
                    .map((_filter) => {
                      formik.setFieldValue(_filter.name, {});
                    });

                  setFilterValues({
                    ...formik.values,
                    [filter.name]: value,
                    fieldName: filter.name,
                  });
                }}
                name={filter.name}
              />
            </Dropdown>
          );
        }
        if (_filter.type === "number") {
          const filter = _filter as NumberFilterField;
          return (
            <NumberField
              id={filter.name}
              name={`${filter.name}`}
              key={`${filter.name}`}
              label={filter.label}
              value={formik?.values?.[filter?.name]}
              max={filter.max}
              min={filter.min}
              placeholder={filter.label}
              onChange={(value: number) => {
                formik.setFieldValue(filter.name, value);
                setFilterValues({
                  ...formik.values,
                  [filter.name]: value,
                  fieldName: filter.name,
                });
              }}
            />
          );
        }
        return <></>;
      })}
    </>
  );
};

export default FilterForm;
