import React, { useState, VFC } from "react";
import { Field, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import TextInput from "../FormInputs/TextInput";
import * as Yup from "yup";
import "./styles.scss";
import i18next from "i18next";
import AccountData from "./AccountDataBlock";
import Button from "../Button";
import AccountManage from "~/components/ProfileSettings/AccountManager";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { selectSocialLogin } from "~/store/slices/user";

export type ProfileSettingsModel = {
  password?: string;
  passwordRepeat?: string;
  new_password: string;
  newPasswordRepeat?: string;
  type?: "private" | "company";
};

export type ProfileSettingsFormProps = {
  onSubmit: (values: ProfileSettingsModel) => void;
  onClick: (editable: boolean) => void;
  type: "private" | "company";
  loading?: boolean;
  initialValues: ProfileSettingsModel;
};

const ProfileSettingsFormSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, i18next.t("ERRORS.PASS_INVALID"))
    .required(i18next.t("ERRORS.PASS_REQ")),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref("password"), null], i18next.t("ERRORS.PASS_REPEAT"))
    .required(i18next.t("ERRORS.PASS_REPEAT_REQ")),
  new_password: Yup.string()
    .min(5, i18next.t("ERRORS.PASS_INVALID"))
    .required(i18next.t("ERRORS.PASS_REQ")),
  newPasswordRepeat: Yup.string()
    .oneOf([Yup.ref("new_password"), null], i18next.t("ERRORS.PASS_REPEAT"))
    .required(i18next.t("ERRORS.PASS_REPEAT_REQ")),
});

const ProfileSettingsForm: VFC<ProfileSettingsFormProps> = ({
  onSubmit,
  onClick,
  loading,
}) => {
  const { t } = useTranslation();
  const socialLogin = useSelector(selectSocialLogin);
  const initialValues: ProfileSettingsModel = {
    password: "",
    new_password: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ProfileSettingsFormSchema}
    >
      {(formik: FormikProps<ProfileSettingsModel>) => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="settings-form">
              <h2 className="profile-block__header h2">
                {t(`PROFILE.SETTINGS`)}
              </h2>
              <div className="profile-setting__wrapper">
                <div>
                  <h3 className="h3">{t("PROFILE.ACCOUNT_DETAILS")}</h3>
                  <AccountData />
                  {!socialLogin && (
                    <div className="settings-form__block">
                      <div className="settings-form__input-wrapper">
                        <Field
                          type={"password"}
                          name="password"
                          component={TextInput}
                          placeholder={t("PLACEHOLDERS.PASSWORD")}
                          label={t("LABELS.PASSWORD")}
                        />
                      </div>
                      <div className="settings-form__input-wrapper">
                        <Field
                          type={"password"}
                          name="passwordRepeat"
                          component={TextInput}
                          placeholder={t("PLACEHOLDERS.REPEAT_PASSSWORD")}
                        />
                      </div>
                      <div className="settings-form__input-wrapper">
                        <Field
                          type={"password"}
                          name="new_password"
                          component={TextInput}
                          placeholder={t("PLACEHOLDERS.NEW_PASSWORD")}
                          label={t("LABELS.NEW_PASSWORD")}
                        />
                      </div>
                      <div className="settings-form__input-wrapper">
                        <Field
                          type={"password"}
                          name="newPasswordRepeat"
                          component={TextInput}
                          placeholder={t("PLACEHOLDERS.REPEAT_NEW_PASSSWORD")}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <AccountManage />
                </div>
              </div>
            </div>

            <div>
              <div className="profile-setting__button-container">
                <Button type="submit" className="profile-setting__save">
                  {loading ? (
                    <ClipLoader color="white" size={20} />
                  ) : (
                    t("BUTTONS.SAVE_UPDATE")
                  )}
                </Button>
                <Button
                  onClick={() => onClick(true)}
                  className="profile-setting__cancel"
                >
                  {t("common.CANCEL")}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfileSettingsForm;
