// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  display: flex;
  flex-direction: column;
}
.login-form > * {
  margin: 10px 0;
}
.login-form label p {
  padding-top: 4px;
  font-family: "Tajawal-Light";
  margin: 0 5px;
}
.login-form input[type=checkbox] {
  width: 18px;
  height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,cAAA;AADJ;AAIE;EACE,gBAAA;EACA,4BAAA;EACA,aAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;AAHJ","sourcesContent":["@import \"/src/styles/variables\";\n\n.login-form {\n  display: flex;\n  flex-direction: column;\n\n  & > * {\n    margin: 10px 0;\n  }\n\n  label p {\n    padding-top: 4px;\n    font-family: 'Tajawal-Light';\n    margin: 0 5px;\n  }\n\n  input[type=\"checkbox\"] {\n    width: 18px;\n    height: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
