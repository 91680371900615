import i18next from "i18next";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useBreadcrumbs, { BreadcrumbData } from "use-react-router-breadcrumbs";
import "./styles.scss";
import { useSelector } from 'react-redux';
import { selectLang } from '~/store/slices/user';

export type BreadcrumbMatch = {
  path: string
}
export type Breadcrumb = {
  breadcrumb: string;
  match: BreadcrumbMatch;
};

export type BreadcrumbsProps = {
  className?: string;
  currentBreadcrumb?: Breadcrumb;
};

const Breadcrumbs: VFC<BreadcrumbsProps> = ({
  className,
  currentBreadcrumb
}) => {
  const breadcrumbs = useBreadcrumbs();
  const { t } = useTranslation();
  const lang = useSelector(selectLang);

  if (breadcrumbs?.length <= 1) {
    return null;
  }
  const breadcrumbsValidated = currentBreadcrumb
    ? [...breadcrumbs.slice(0, -1), currentBreadcrumb]
    : breadcrumbs;


  return (
    <div className={`breadcrumbs ${className ? className : ""}`}>
      {breadcrumbsValidated.map(
        ({ breadcrumb, match }, key) =>{

          return (i18next.exists(
                `BREADCRUMB.${breadcrumb?.props?.children?.toLocaleUpperCase()}`
              ) &&
              (key + 1 === breadcrumbsValidated?.length ? (
                <span className="breadcrumb breadcrumb--current" key={key}>
                {t(
                  `BREADCRUMB.${breadcrumb?.props?.children?.toLocaleUpperCase()}`
                )}
              </span>
              ) : (
                <Link className="breadcrumb" key={key} to={match.path ==="/" ? `/${lang}` : match.path}>
                  {t(
                    `BREADCRUMB.${breadcrumb?.props?.children?.toLocaleUpperCase()}`
                  )}
                </Link>
              ))) ||
            (match?.path === "productPage" && (
              <span className="breadcrumb breadcrumb--current" key={key}>
             {breadcrumb}
            </span>
            ))
        }

      )}
    </div>
  );
};
export default Breadcrumbs;
